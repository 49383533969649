import React, {Component, Fragment} from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavLink,
    NavItem,
    NavbarText,
    Container
} from 'reactstrap';
import {Link} from "react-router-dom"
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import LoginModal from "./auth/LoginModal";
import Logout from "./auth/Logout";

class AppNavbar extends Component {
    state = {
        isOpen: false
    };

    static propTypes = {
        auth: PropTypes.object.isRequired
    };

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    };

    render() {
        let {isAuthenticated, user} = this.props.auth;
        if(!user){
            user = JSON.parse(localStorage.getItem('user'));
        }
        const authLinks = (
            <Fragment>
                {
                    /*
                    <NavItem>
                    <Link to="/">
                    <NavLink href="/">
                        Actions
                    </NavLink>
                    </Link>
                </NavItem>
                     */
                }
                <NavItem>
                    <Link to="/prospectList">
                        <NavLink href="/prospectList">
                            Prospects
                        </NavLink>
                    </Link>
                </NavItem>

                {/*<NavItem>
                    <Link to="/prospectListMail">
                        <NavLink href="/prospectListMail">
                            Prospects Mail
                        </NavLink>
                    </Link>
                </NavItem>
                    <NavItem>
                    <Link to="/prospectListRS">
                    <NavLink href="/prospectListRS">
                    Prospects RS
                    </NavLink>
                    </Link>
                    </NavItem>
                */}
                {user && user?.role == 'admin' ?
                    <Fragment>
                        <NavItem>
                            <Link to="/customerList">
                                <NavLink href="/customerList">
                                    Clients
                                </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/olderCustomerList">
                                <NavLink href="/olderCustomerList">
                                    Anciens Clients
                                </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/billingList">
                                <NavLink href="/billingList">
                                    Facturation
                                </NavLink>
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link to="/userList">
                                <NavLink href="/userList">
                                        Utilisateurs
                                </NavLink>
                            </Link>
                        </NavItem>
                    </Fragment>
                    : user && user?.role == 'gestionnaire_client'?
                        <Fragment>
                            <NavItem>
                                <Link to="/customerList">
                                    <NavLink href="/customerList">
                                        Clients
                                    </NavLink>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link to="/olderCustomerList">
                                    <NavLink href="/olderCustomerList">
                                        Anciens Clients
                                    </NavLink>
                                </Link>
                            </NavItem>
                        </Fragment>
                        : null
                }
                <NavItem>
                    <Logout/>
                </NavItem>
            </Fragment>
        );

        const guestLinks = (
            <Fragment>
                <NavItem>
                    <LoginModal/>
                </NavItem>
            </Fragment>

        )
        return (
            <div>
                <Navbar color="dark" dark expand="md" className="mb-5">
                    <Container>
                            <NavbarBrand>
                                CRM iweb Marketing
                            </NavbarBrand>
                            <NavbarToggler onClick={this.toggle}/>
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                {isAuthenticated ? authLinks : guestLinks}
                            </Nav>
                            <NavbarText>
                                <strong> {user ? `Bienvenue ${user.firstname? user.firstname: ''}` + ' ' +
                                    `${user.lastname? user.lastname: ''}` +
                                    ` Role : ${user?.role === 'admin' ? 'Admin' :
                                        user.role === 'gestionnaire_client' ? 'Gestionnaire Client' :
                                        user.role === 'commercial' ? 'Commercial' : ''}` : '' }</strong>
                            </NavbarText>
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(AppNavbar);