import React from 'react'
import { connect } from 'react-redux';
import {Table} from "reactstrap";
import moment from "moment";

moment.locale('fr');


const mapStateToProps = (state) => {
    return{
        getTaskDetail: state.tasks.getTaskDetail,
        errorTaskDetail: state.tasks.errorTaskDetail,
    }
}

let DetailTaskComponent = (props) => {
    return (
        <Table striped>
            <tbody>
                <tr>
                    <td width="200">
                        Nom
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {
                            props.getTaskDetail.type
                                ? props.getTaskDetail.type
                                : 'Aucun type'
                        }
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Description
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {
                            props.getTaskDetail.description
                            ? props.getTaskDetail.description
                            : 'Aucune description'
                        }
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        A faire le :
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {moment(props.getTaskDetail.date).format('L')}
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Status
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {props.getTaskDetail.status ? 'A faire' : 'Fait'}
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Responsable
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {props.getTaskDetail.user_firstname ?
                            props.getTaskDetail.user_firstname : 'Non défini'}

                        {props.getTaskDetail.user_lastname ?
                            ' ' + props.getTaskDetail.user_lastname : null}

                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Pour le client
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {props.getTaskDetail.customer_firstname ?
                            props.getTaskDetail.customer_firstname : 'Non défini'}

                        {props.getTaskDetail.customer_lastname ?
                            ' ' + props.getTaskDetail.customer_lastname : null}

                    </td>
                </tr>
            </tbody>
        </Table>
    )
}


export default connect(mapStateToProps, null)(DetailTaskComponent)