import {
    GET_COMMENTS_DETAILS,
    ADD_COMMENT,
    UPDATE_COMMENT,
    DELETE_COMMENT,
    COMMENTS_LOADING, GET_ALL_COMMENTS_OF_CUSTOMER,
} from "../actions/types";

const initialState = {
    comments: [],
    getAllCommentsOfCustomer: [],
    getCommentDetail: false,
    getResponDataComment: false,
    errorResponDataComment: false,
    errorCommentDetail: false,
    loading: false,
    errorCommentsList: false
}

const comments = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_COMMENTS_OF_CUSTOMER:
            return {
                ...state,
                getAllCommentsOfCustomer: action.payload.data,
                loading: false
            };
        case GET_COMMENTS_DETAILS:
            return {
                ...state,
                getCommentDetail: action.payload.data,
                errorCommentDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_COMMENT:
            return {
                ...state,
                comments: state.comments.filter(comment => comment._id !== action.payload)
            };
        case ADD_COMMENT:
            return {
                ...state,
                getResponDataComment: action.payload.data,
                errorResponDataComment: action.payload.errorMessage,
                loading: false

            };
        case UPDATE_COMMENT:
            return {
                ...state,
                getResponDataComment: action.payload.data,
                errorResponDataComment: action.payload.errorMessage
            }
        case COMMENTS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default comments