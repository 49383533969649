import {
    GET_DOCUMENTS_DETAILS,
    ADD_DOCUMENT,
    UPDATE_DOCUMENT,
    DELETE_DOCUMENT,
    DOCUMENTS_LOADING, GET_ALL_TASKS_OF_CUSTOMER, GET_ALL_DOCUMENTS_OF_CUSTOMER,
} from "../actions/types";

const initialState = {
    documents: [],
    getDocumentDetail: false,
    getAllDocumentsOfCustomer: false,
    getResponDataDocument: false,
    errorResponDataDocument: false,
    errorDocumentDetail: false,
    loading: false,
    errorDocumentsList: false
}

const documents = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_DOCUMENTS_OF_CUSTOMER:
            return {
                ...state,
                getAllDocumentsOfCustomer: action.payload.data,
                loading: false
            };
        case GET_DOCUMENTS_DETAILS:
            return {
                ...state,
                getDocumentDetail: action.payload.data,
                errorDocumentDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_DOCUMENT:
            return {
                ...state,
                documents: state.documents.filter(document => document._id !== action.payload),
                loading: false
            };
        case ADD_DOCUMENT:
            return {
                ...state,
                getResponDataDocument: action.payload.data,
                errorResponDataDocument: action.payload.errorMessage,
                loading: false
            };
        case UPDATE_DOCUMENT:
            return {
                ...state,
                getResponDataDocument: action.payload.data,
                errorResponDataDocument: action.payload.errorMessage,
                loading: false
            }
        case DOCUMENTS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default documents