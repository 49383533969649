import React, {Component} from 'react';
import CustomerTableComponent from "../../components/Customers/CustomerTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllCustomers, getAllProspectsMailFollowed, deleteDataCustomer, getAllProspectsMail} from '../../actions/customerActions';
import {getAllUsers} from "../../actions/userActions";
import ProspectTableComponent from "../../components/Prospect/ProspectTableComponent";


 class ProspectMailContainer extends Component {

     state = {
         msg: null
     }
     static propTypes = {
         getAllProspectsMail: PropTypes.func.isRequired,
         getAllProspectsMailFollowed: PropTypes.func.isRequired,
         isAuthenticated: PropTypes.bool,
     }

     componentDidMount() {
         this.props.getAllProspectsMail();
         this.props.getAllProspectsMailFollowed();
         this.props.getAllUsers();
         this.props.deleteDataCustomer();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <ProspectTableComponent isProspectsMail = {true}/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllProspectsMail: state.customers.getAllProspects,
    getAllProspectsMailFollowed: state.customers.getAllProspectsMailFollowed,

    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllProspectsMail, getAllProspectsMailFollowed, getAllUsers, deleteDataCustomer})(ProspectMailContainer)