import React, {Component} from 'react';
import {Container} from "@material-ui/core";
import BackComponent from "../../components/BackComponent";
import {connect} from 'react-redux';
import {getAllUsers} from "../../actions/userActions";
import {addCustomer, getAllOlderCustomers} from "../../actions/customerActions";
import Swal from 'sweetalert2';
import CustomerFormComponent from "../../components/Customers/CustomerFormComponent";
import {Link, Redirect} from "react-router-dom";

const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllOlderCustomers: state.customers.getAllOlderCustomers ? state.customers.getAllOlderCustomers : JSON.parse(localStorage.getItem('allOlderCustomers')),
        getResponDataCustomer: state.customers.getResponDataCustomer,
        errorResponDataCustomer: state.customers.errorResponDataCustomer,

    };
};

 class CreateOlderCustomerContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getAllUsers());
         this.props.dispatch(getAllOlderCustomers());
     }

    handleSubmit(data){
        if(data.user_id){
            data.user_id = data.user_id?.value;
        }
     {this.props.getAllUsers.map((user) => {
                if(user._id == data.user_id){
                    data.user_firstname = user.firstname
                    data.user_lastname = user.lastname
                }
            }
        )}
        if(data.customer_id){
            data.customer_id = data.customer_id?.value;
        }
        {this.props.getAllOlderCustomers.map((customer) => {
                if(customer._id == data.customer_id){
                    data.customer_firstname = customer.firstname
                    data.customer_lastname = customer.lastname
                }
            }
        )}
        this.props.dispatch(addCustomer(data))
    }

     render() {
         if(this.props.getResponDataCustomer || this.props.errorResponDataCustomer) {
             if (this.props.errorResponDataCustomer) {
                 Swal.fire(
                     "Echec ! ",
                     this.props.errorResponDataCustomer,
                     "error"
                 );
             } else {
                 Swal.fire({
                     title: this.props.getResponDataCustomer.membership ? "Client crée !" : "Prospect crée !",
                     icon :"success",
                     showCloseButton: true,
                 }).then((result) => {
                     /* Read more about isConfirmed, isDenied below */
                     if (result.isConfirmed) {
                         window.history.back();
                     }
                 })
             }
         }
         return (
            <Container>
                <BackComponent />
                <h1>Crée un ancien client</h1>
                <CustomerFormComponent isOlderCustomer = {true} onSubmit={(data) => this.handleSubmit(data)}/>
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(CreateOlderCustomerContainer)