import React, {Component} from 'react';
import {Container} from "reactstrap";
import BackComponent from '../../components/BackComponent';
import {connect} from 'react-redux';
import FormComponent from "../../components/Billing/BillingFormComponent.js";
import {getTaskDetails, updateTask} from "../../actions/taskActions";
import Swal from "sweetalert2";
import moment from "moment";
import {addbilling, getBillingDetails, updateBilling} from "../../actions/billingActions";


const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getBillingDetail: state.billings.getBillingDetail,
        getResponDataBilling: state.billings.getResponDataBilling,
        errorResponDataBilling: state.billings.errorResponDataBilling,

    };
};

 class EditBillingContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getBillingDetails(this.props.match.params.id));
     }

     handleSubmit(data){
         data.date = new Date(data.date).toISOString();

         if(data.user_id){
             data.user_id = data.user_id?.value;
         }

         {this.props.getAllUsers.map((user) => {
                 if(user._id == data.user_id){
                     data.user_firstname = user.firstname ? user.firstname : '';
                     data.user_lastname = user.lastname ? user.lastname : '';
                 }
             }
         )}

         if(data.customer_id){
             data.customer_id = data.customer_id?.value;
         }
         {this.props.getAllCustomers.map((customer) => {
                 if(customer._id == data.customer_id){
                     data.customer_firstname = customer.firstname ? customer.firstname : '';
                     data.customer_lastname = customer.lastname ? customer.lastname : '';
                 }
             }
         )}
         this.props.dispatch(updateBilling(data, this.props.match.params.id));
         let newData = data;
         if(newData.billing_status === "paye"){
             newData.date = moment(newData.date).add(1, 'M');
             newData.billing_status = "en_attente";
             this.props.dispatch(addbilling(newData))
         }
     }
    render() {
        if(this.props.getResponDataBilling || this.props.errorResponDataBilling) {
            if (this.props.errorResponDataBilling) {
                Swal.fire(
                    "Echec ! ",
                    this.props.errorResponDataBilling,
                    "error"
                );
            } else {
                Swal.fire({
                    title: "Facture modifiée !",
                    icon :"success"
                }).then((result) => {
                    if(result.isConfirmed){
                        window.history.back();
                    }
                });
            }
        }

        return (
            <Container>
                <BackComponent />
                <h1>Modifier la facture {this.props.getBillingDetail.type}</h1>
                <FormComponent onSubmit={(data) => this.handleSubmit(data)} />
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(EditBillingContainer)