import React, {Component} from 'react';
import TaskTableComponent from "../../components/Task/TaskTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllTasks, deleteDataTask, getAllTasksOfToday, getAllTasksOfUser, getAllTasksOfTodayOfUser} from '../../actions/taskActions';
import {getAllUsers} from "../../actions/userActions";
import {getAllCustomers} from "../../actions/customerActions";
import {
    deleteDataBilling,
    getAllBillings,
    getAllBillingsOfToday,
    getAllBillingsOfTodayOfUser,
    getAllBillingsOfUser
} from "../../actions/billingActions";
import BillingTableComponent from "../../components/Billing/BillingTableComponent";


 class BillingContainer extends Component {

     state = {
         msg: null,
     }

     componentDidMount() {
         const user = JSON.parse(localStorage.getItem('user'));
         this.props.getAllBillings();
         this.props.getAllBillingsOfToday();
         this.props.isAuthenticated || user ? this.props.getAllBillingsOfTodayOfUser(user.id) : null;
         this.props.isAuthenticated || user ? this.props.getAllBillingsOfUser(user.id): null;
         this.props.getAllCustomers();
         this.props.getAllUsers();
         this.props.deleteDataBilling();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <BillingTableComponent/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllBillings: state.billings.getAllBillings,
    getAllBillingsOfToday: state.billings.getAllBillingsOfToday,
    getAllBillingsOfTodayUser: state.billings.getAllBillingsOfTodayUser,
    getAllBillingsOfUser: state.billings.getAllBillingsOfUser,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllBillings,getAllCustomers, getAllBillingsOfToday, getAllBillingsOfTodayOfUser, getAllBillingsOfUser, getAllUsers, deleteDataBilling})(BillingContainer)