import React, { Component, useState, useRef} from 'react';
import {reduxForm, Field} from 'redux-form';
import { connect} from 'react-redux';
import {Form, FormGroup, Col, Label, Input, Row, Button, Spinner} from "reactstrap";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment-localizer'
import TaskValidation from "../validation/TaskValidation";
import  { Link, Redirect } from 'react-router-dom';
import CustomerValidation from "../validation/CustomerValidation";
import Dropzone from 'react-dropzone';
import {getCustomerDetails} from "../actions/customerActions";
import axios from "../axios";

momentLocalizer(moment);

const mapStateToProps = (state) => {
    let user = JSON.parse(localStorage.getItem('user'));

    return {
        getAllUsers: state?.users?.getAllUsers ? state?.users?.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state?.customers?.getAllCustomers ? state?.customers?.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        getUserToken: localStorage.getItem('token'),
        getCustomerDetail: state?.customers?.getCustomerDetail,
        getResponDataDocument: state?.customers?.getResponDataDocument,
        errorResponDataDocument: state?.customers?.errorResponDataDocument,
    };
};

const DocumentFormComponent = (props) => {

        const [file, setFile] = useState(null); // state for storing actual image
        const [previewSrc, setPreviewSrc] = useState(''); // state for storing previewImage
        const [state, setState] = useState({
            name: ''
        });
        const [errorMsg, setErrorMsg] = useState('');
        const [isLoading, setIsLoading] = useState(false);
        const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
        const dropRef = useRef(); // React ref for managing the hover state of droppable area

        const handleInputChange = (event) => {
            setState({
                ...state,
                [event.target.name]: event.target.value
            });
        };

        const onDrop = (files) => {
            setErrorMsg('');
            const [uploadedFile] = files;
            if(files[0].size > 5000000)
            {
                setErrorMsg('Veuillez sélectionner un fichier inferieur a 5MB.');
            }
            else {
                setFile(uploadedFile);
                console.log(files);

                const fileReader = new FileReader();
                fileReader.onload = () => {
                    setPreviewSrc(fileReader.result);
                };
                fileReader.readAsDataURL(uploadedFile);
                setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|JPEG|JPG|jpg|PNG|png)$/));
                dropRef.current.style.border = '2px dashed #e9ebeb';
            }
        };

        const updateBorder = (dragState) => {
            if (dragState === 'over') {
                dropRef.current.style.border = '2px solid #000';
            } else if (dragState === 'leave') {
                dropRef.current.style.border = '2px dashed #e9ebeb';
            }
        };

    const handleOnSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const { name } = state;
            if (name.trim() !== '' && props.getCustomerDetail._id.trim() !== '') {
                if (file) {
                    const formData = new FormData();
                    formData.append('file', file);
                    formData.append('name', name);
                    formData.append('customer_id', props.getCustomerDetail._id);
                    props.getCustomerDetail.firstname ? formData.append('customer_firstname', props.getCustomerDetail.firstname): null;
                    props.getCustomerDetail.lastname ? formData.append('customer_lastname', props.getCustomerDetail.lastname): null;
                    props.getUserConnected.id ? formData.append('user_id', props.getUserConnected.id): formData.append('user_id', props.getCustomerDetail.user_id);
                    props.getUserConnected.firstname ?formData.append('user_firstname', props.getUserConnected.firstname): null;
                    props.getUserConnected.lastname ? formData.append('user_lastname', props.getUserConnected.lastname): null;

                    setErrorMsg('');
                    await axios.post('/api/documents/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            //"Access-Control-Allow-Credentials": "true",
                            //"Access-Control-Allow-Methods": ['GET','HEAD','OPTIONS','POST','PUT'],
                            //'Access-Control-Allow-Origin': '*',
                            //"Access-Control-Allow-Headers": ['Access-Control-Allow-Headers','Access-Control-Allow-Methods','Origin','X-Requested-With','Content-Type','Accept','Authorization']
                        }
                    })
                    setIsLoading(false);
                    window.history.back();

                } else {
                    setErrorMsg('Veuillez sélectionner un fichier à ajouter.');
                }
            } else {
                setErrorMsg('Veuillez saisir toutes les valeurs de champ.');
            }
        } catch (error) {
            error?.response && setErrorMsg(error?.response?.data);
            setIsLoading(false);
        }
    };

        return (
                <Form className="search-form" onSubmit={handleOnSubmit}>
                    {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                    <Row>
                        <Col>
                            <FormGroup controlId="name">
                                <Input
                                    type="text"
                                    name="name"
                                    value={state.name || ''}
                                    placeholder="Entrer le nom du document"
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className="upload-section">
                        <Dropzone onDrop={onDrop}
                                  onDragEnter={() => updateBorder('over')}
                                  onDragLeave={() => updateBorder('leave')}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
                                    <input {...getInputProps()} />
                                    <p style={{"text-align": "center"}}>Drag and drop un document OU clique ici pour selectionner un fichier</p>
                                    {file && (
                                        <div>
                                            <strong>Document selectionné:</strong> {file.name}
                                        </div>
                                    )}
                                </div>
                            )}
                        </Dropzone>
                        {previewSrc ? (
                            isPreviewAvailable ? (
                                <div className="image-preview">
                                    <img className="preview-image" src={previewSrc} alt="Preview" />
                                </div>
                            ) : (
                                <div className="preview-message">
                                    <p>Aucun aperçu disponible pour ce fichier</p>
                                </div>
                            )
                        ) : (
                            <div className="preview-message">
                                <p>L'aperçu de l'image sera affiché ici après la sélection</p>
                            </div>
                        )}
                    </div>
                    <Button color="dark" type="submit">
                        Submit
                    </Button>
                    <div className="text-sm-left" style={{"width":"50%"}}>
                        {
                            isLoading && !errorMsg ?
                                <div>
                                    Veuillez patienter pendant le chargement du fichier, cela peut prendre jusqu'a une minute.
                                    <Spinner color="dark"/>
                                </div>
                                : null
                        }
                    </div>
                </Form>
        );
}

export default connect(mapStateToProps, null)(DocumentFormComponent);