import React, {Component} from 'react';
import UserTableComponent from "../../components/User/UserTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllUsers, deleteDataUser} from "../../actions/userActions";
import {getAllCustomers} from "../../actions/customerActions";
import  { Link, Redirect } from 'react-router-dom';



 class UserContainer extends Component {

     state = {
         msg: null
     }
     static propTypes = {
         getAllUsers: PropTypes.func.isRequired,
         getAllCustomers: PropTypes.func.isRequired,
         deleteUserDetails: PropTypes.func.isRequired,
         users: PropTypes.object.isRequired,
         isAuthenticated: PropTypes.bool,
         error: PropTypes.object.isRequired,
         clearErrors: PropTypes.func.isRequired,
     }

     componentDidMount() {
         this.props.getAllUsers();
         this.props.getAllCustomers();
         this.props.deleteDataUser();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated && this.props?.getUserConnected?.role === 'admin' &&
                <div>
                    <UserTableComponent/>
                </div>
                }
                {this.props?.getUserConnected?.role !== 'admin' &&
                <div>
                    <h2>Vous ne possedez pas les accés pour accéder à cette page</h2>
                    <Link to='/'/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllUsers: state.tasks.getAllUsers,
    getUserConnected: JSON.parse(localStorage.getItem('user')),
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllUsers, getAllCustomers, deleteDataUser})(UserContainer)