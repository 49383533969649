import React, {Component} from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import BackComponent from "../../components/BackComponent";
import { connect } from "react-redux";
import {deleteDataTask, getAllTasksOfCustomer, getAllTasksOfUser, getTaskDetails} from "../../actions/taskActions";
import DetailTaskComponent from "../../components/Task/DetailTaskComponent";
import {getUserDetails} from "../../actions/userActions";
import {Link, Redirect} from "react-router-dom";
import DetailUserComponent from "../../components/User/DetailUserComponent";


const mapStateToProps = (state) => {
    return{
        getUserDetail: state.users.getUserDetail,
        errorUserDetail: state.users.errorUserDetail,
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        isAuthenticated: state.auth.isAuthenticated
    }
}

 class DetailUserContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getUserDetails(this.props.match.params.id));
         this.props.dispatch(getAllTasksOfUser(this.props.match.params.id));
         this.props.dispatch(deleteDataTask());
     }

     render() {
        return (
            <Container>
                <div>
                    {this.props.isAuthenticated && this.props?.getUserConnected?.role === 'admin' &&
                    <div>
                        <BackComponent />
                        <h1>Détails de l'utilisateur {this.props.getUserDetail.firstname + ' ' + this.props.getUserDetail.lastname} </h1>
                        <DetailUserComponent />
                    </div>
                    }
                    {this.props?.getUserConnected?.role !== 'admin' &&
                    <div>
                        <h2>Vous ne possedez pas les accés pour accéder à cette page</h2>
                        <Link to='/'/>
                    </div>
                    }
                    {!this.props.isAuthenticated &&
                    <div>
                        <h2>Veuillez vous connecter pour accéder au CRM</h2>
                    </div>
                    }

                </div>
            </Container>
        )
    }
}

export default connect(mapStateToProps, null)(DetailUserContainer)