import React from 'react'
import { connect } from 'react-redux';
import {Table} from "reactstrap";
import Moment from "react-moment";
import moment from "moment";
import {Search} from "react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
import {deleteTask} from "../../actions/taskActions";
import TaskUserTableComponent from "./TaskUserTableComponent";

moment.locale('fr');

const mapStateToProps = (state) => {
    return{
        getUserDetail: state.users.getUserDetail,
        getAllTasksOfCustomer: state.tasks.getAllTasksOfCustomer,
        errorUserDetail: state.users.errorUserDetail,
        isAuthenticated: state.auth.isAuthenticated,
        errorTasksList: state.tasks.errorTasksList,
    }
}

let DetailUserComponent = (props) => {

    return (
        <div>
            <Table striped bordered responsive variant="dark">
                <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prenom</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Adresse</th>
                        <th>Rôle</th>
                        <th>Dernière modification le : </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {
                                props.getUserDetail.lastname
                                    ? props.getUserDetail.lastname
                                    : 'Aucun nom'
                            }
                        </td>
                        <td>
                            {
                                props.getUserDetail.firstname
                                ? props.getUserDetail.firstname
                                : 'Aucun prenom'
                            }
                        </td>
                        <td>
                            {
                                props.getUserDetail.email
                                ? props.getUserDetail.email
                                : 'Aucun email'
                            }
                        </td>
                        <td>
                            {
                                props.getUserDetail.phone
                                    ? props.getUserDetail.phone
                                    : 'Aucun numero de téléphone'
                            }
                        </td>
                        <td>
                            {
                                props.getUserDetail.street ? props.getUserDetail.street + ' ' : null
                            }
                            {
                                props.getUserDetail.zip ? props.getUserDetail.zip + ' ' : null
                            }
                            {
                                props.getUserDetail.city ? props.getUserDetail.city + ' ' : null
                            }
                            {
                                props.getUserDetail.state ? props.getUserDetail.state + ' ' : null
                            }
                            {
                                !props.getUserDetail.street && !props.getUserDetail.zip && !props.getUserDetail.city && !props.getUserDetail.state ?
                                    'Aucune adresse'
                                    : null

                            }
                        </td>
                        <td>
                            {props.getUserDetail.role === 'admin' ? 'Admin' : 'Commercial'}
                        </td>
                        <td>
                            <Moment local format="DD/MM/YYYY">{props.getUserDetail.register_date}</Moment>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <TaskUserTableComponent />

        </div>
    )
}


export default connect(mapStateToProps, null)(DetailUserComponent)