import React, { Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import { connect} from 'react-redux';
import { FormGroup, Col, Label, Input, Row, Button } from "reactstrap";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment-localizer'
import TaskValidation from "../../validation/TaskValidation";
import  { Link, Redirect } from 'react-router-dom';
import Select from "react-select";


momentLocalizer(moment);

const mapStateToProps = (state) => {
    let user = JSON.parse(localStorage.getItem('user'));
    return {
        initialValues: {
            type: state.tasks.getTaskDetail.type || 'Rappel',
            description: state.tasks.getTaskDetail.description,
            date: moment(state.tasks.getTaskDetail.date).format('YYYY-MM-DD'),
            status: state.tasks.getTaskDetail.status || true,
            user_id: state.tasks.getTaskDetail.user_id ? ({
                label: (state.tasks.getTaskDetail.user_firstname ?
                    state.tasks.getTaskDetail.user_firstname : '') + ' ' +
                    (state.tasks.getTaskDetail.user_lastname ? state.tasks.getTaskDetail.user_lastname : ''),
                value : state.tasks.getTaskDetail.user_id
            }): user.id ? {
                label: (user.firstname ? user.firstname : '') + ' ' + (user.lastname ? user.lastname : ''),
                value : user.id
            }: null,
            user_firstname: state.tasks.getTaskDetail.user_firstname || user.firstname,
            user_lastname: state.tasks.getTaskDetail.user_lastname || user.lastname,
            customer_id: state.tasks.getTaskDetail.customer_id ? {
                label: (state.tasks.getTaskDetail.customer_firstname ?
                    state.tasks.getTaskDetail.customer_firstname : '' )+ ' ' +
                    (state.tasks.getTaskDetail.customer_lastname ? state.tasks.getTaskDetail.customer_lastname : ''),
                value : state.tasks.getTaskDetail.customer_id
            }: state.customers.getCustomerDetail._id ? {
                    label: (state.customers.getCustomerDetail.firstname ?
                        state.customers.getCustomerDetail.firstname : '' )+ ' ' +
                        (state.customers.getCustomerDetail.lastname ? state.customers.getCustomerDetail.lastname : ''),
                    value: state.customers.getCustomerDetail._id,
                }: null,
            customer_firstname: state.tasks.getTaskDetail.customer_firstname || state.customers.getCustomerDetail.firstname,
            customer_lastname: state.tasks.getTaskDetail.customer_lastname || state.customers.getCustomerDetail.lastname,
        },
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        getUserToken: localStorage.getItem('token'),
        getTaskDetail: state.tasks.getTaskDetail,
        getResponDataTask: state.tasks.getResponDataTask,
        errorResponDataTask: state.tasks.errorResponDataTask,
    };
};

const renderFieldInput = ({
                              input,
                              type,
                              placeholder,
                              label,
                              disabled,
                              readOnly,
                              meta: { touched, error, warning },
                          }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            />
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);


class TaskFormComponent extends Component {

    render()
    {
        if(!this.props.getUserConnected || !this.props.getUserToken){
            return <Link to="/"/>
        }
        const renderFieldSelectStatus = ({
                                             input,
                                             type,
                                             placeholder,
                                             label,
                                             disabled,
                                             readOnly,
                                             meta: { touched, error, warning },
                                         }) => (
            <Row>
                <Col md="12">
                    <Label htmlFor="{input}" className="col-form-label">
                        {label}
                    </Label>
                </Col>
                <Col md="12">
                    <Input
                        {...input}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={readOnly}
                    >
                        <option value={true}>A faire</option>
                        <option  value={false}>Fait</option>
                    </Input>
                    {touched &&
                    ((error && <p style={{ color: "red" }}>{error}</p>) ||
                        (warning && <p style={{ color: "brown" }}>{warning}</p>))}
                </Col>
            </Row>
        );

const renderFieldSelectType = ({
                                             input,
                                             type,
                                             placeholder,
                                             label,
                                             disabled,
                                             readOnly,
                                             meta: { touched, error, warning },
                                         }) => (
            <Row>
                <Col md="12">
                    <Label htmlFor="{input}" className="col-form-label">
                        {label}
                    </Label>
                </Col>
                <Col md="12">
                    <Input
                        {...input}
                        type={type}
                        placeholder={placeholder}
                        disabled={disabled}
                        readOnly={readOnly}
                    >
                        <option value={'Rappel'}>Rappel</option>
                        <option value={'Envoie Doc'}>Envoie Doc</option>
                        <option value={'Rendez-vous telephonique'}>Rendez-vous téléphonique</option>
                        <option value={'A suivre'}>A suivre</option>
                        <option value={'Autre'}>Autre</option>
                    </Input>
                    {touched &&
                    ((error && <p style={{ color: "red" }}>{error}</p>) ||
                        (warning && <p style={{ color: "brown" }}>{warning}</p>))}
                </Col>
            </Row>
        );

        const renderDateTimePicker = ({
                                          input,
                                          type,
                                          placeholder,
                                          label,
                                          disabled,
                                          readOnly,
                                          defaultValue,
                                          meta: { touched, error, warning },
                                      }) => (
            <Row>
                <Col md="12">
                    <Label for="status">
                        {label}
                    </Label>
                </Col>
                <Col md="12">
                    <Input
                        {...input}
                        type="date"
                        name="date"
                        defaultValue={this.props.getTaskDetail.date ?
                            moment(this.props.getTaskDetail.date).format('YYYY-MM-DD')
                            : moment().format('YYYY-MM-DD') }
                        placeholder="date">
                    </Input>
                    {touched &&
                    ((error && <p style={{ color: "red" }}>{error}</p>) ||
                        (warning && <p style={{ color: "brown" }}>{warning}</p>))}
                </Col>
            </Row>
        );

        const renderFieldSelectUserId = ({
                                             input,
                                             name,
                                             placeholder,
                                             label,
                                             options,
                                             id,
                                             meta: { touched, error, warning },
                                         }) => (
            <Row>
                <Col md="12">
                    <Label htmlFor="{input}" className="col-form-label">
                        {label}
                    </Label>
                </Col>
                <Col md="12">
                    <Select
                        placeholder={placeholder}
                        {...input}
                        //id={id}
                        //name={name}
                        options={options}
                        //value={input.value}
                        onChange={(value) => input.onChange(value)}
                        onBlur={() => input.onBlur()}
                        isClearable
                        isSearchable
                        //onChange={value => input.onChange(value)}
                        //value={options.filter(option => option.value === this.props.getCustomerDetail.customer_id)}
                        //options={options}
                    />
                    {touched &&
                    ((error && <p style={{ color: "red" }}>{error}</p>) ||
                        (warning && <p style={{ color: "brown" }}>{warning}</p>))}
                </Col>
            </Row>
        );

        let userId = [];

        this.props.getAllUsers.map((user) => {
            userId.push({
                label: (user.firstname ? user.firstname : '') + ' ' +  (user.lastname ? user.lastname : ''),
                value: user._id
            })})

        const renderFieldSelectCustomerId = ({
                                                 label,
                                                 options,
                                                 input,
                                                 placeholder,
                                                 id,
                                                 name,
                                                 defaultValue,
                                                 meta: { touched, error, warning },
                                             }) => (

            <Row>
                <Col md="12">
                    <Label htmlFor="{input}" className="col-form-label">
                        {label}
                    </Label>
                </Col>

                <Col md="12">
                    <Select
                        placeholder={placeholder}
                        {...input}
                        //id={id}
                        //name={name}
                        options={options}
                        //value={input.value}
                        onChange={(value) => input.onChange(value)}
                        onBlur={() => input.onBlur()}
                        isClearable
                        isSearchable
                        //onChange={value => input.onChange(value)}
                        //value={options.filter(option => option.value === this.props.getCustomerDetail.customer_id)}
                        //options={options}
                    />
                    {touched &&
                    ((error && <p style={{ color: "red" }}>{error}</p>) ||
                        (warning && <p style={{ color: "brown" }}>{warning}</p>))}
                </Col>
            </Row>
        );

        let customerId = [];

        this.props.getAllCustomers.map((customer) => {
            customerId.push({
                label: (customer.firstname ? customer.firstname : '') + ' ' +  (customer.lastname ? customer.lastname : ''),
                value: customer._id
            })})

        /*
        --------------------- Trier le tableau par label-----------------------
        customerId.sort(sortFunction);

        function sortFunction(a, b) {
            if (a.label === b.label) {
                return 0;
            }
            else {
                return (a.label < b.label) ? -1 : 1;
            }
        }*/

        return (
            <form onSubmit={this.props.handleSubmit}>
                <FormGroup row>
                    <Col md="6">
                        <FormGroup>
                            <Field
                                type="select"
                                component={renderFieldSelectType}
                                name="type"
                                label="Type : "
                                placeholder="Type de la tâche"/>
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <Field
                                type="textarea"
                                component={renderFieldInput}
                                label="Description : "
                                placeholder="Description de la tâche"
                                name="description"/>
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <Field
                                component={renderFieldSelectStatus}
                                label="Status de la tâche :"
                                type="select"
                                name="status"/>
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <Field
                                name="date"
                                type="date"
                                label="Date du jour ou la tâche doit être réalisé :"
                                component={renderDateTimePicker}/>
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                                <Field
                                    component={renderFieldSelectUserId}
                                    label="A faire par l'utilisateur :"
                                    options={userId}
                                    name="user_id"
                                    defaultValue={this.props.getUserConnected.id}/>
                        </FormGroup>
                    </Col>

                    <Col md="6">
                        <FormGroup>
                            <Field
                                component={renderFieldSelectCustomerId}
                                label="Relier au client: "
                                placeholder="Relier au client: "
                                options={customerId}
                                name="customer_id"/>
                        </FormGroup>
                    </Col>
                </FormGroup>

                <FormGroup row className="my-0">
                    <Col md="12">
                        <FormGroup>
                            <Button
                                color="dark"
                                type="submit"
                                disabled={this.props.submitting}
                            >
                                Valider
                            </Button>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </form>
        );
    }
}

  TaskFormComponent = reduxForm({
    form: 'formCreateTask',
    validate: TaskValidation,
    enableReinitialize: true,
})(TaskFormComponent);

export default connect(mapStateToProps, null)(TaskFormComponent)