import React, {Component} from 'react';
import {Container} from "reactstrap";
import BackComponent from "../../components/BackComponent";
import { connect } from "react-redux";
import {getBillingDetails} from "../../actions/billingActions";
import DetailBillingComponent from "../../components/Billing/DetailBillingComponent";


const mapStateToProps = (state) => {
    return{
        getBillingDetail: state.billings.getBillingDetail,
        errorBillingDetail: state.billings.errorBillingDetail,
    }
}

 class DetailBillingContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getBillingDetails(this.props.match.params.id));
     }

     render() {
        return (
            <Container>
                <BackComponent />
                <h1>Détails de la facture {this.props.getBillingDetail.type} </h1>
                <DetailBillingComponent />
            </Container>
        )
    }
}

export default connect(mapStateToProps, null)(DetailBillingContainer)