import React, {Component} from 'react';
import CustomerTableComponent from "../../components/Customers/CustomerTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllCustomers, getAllProspectsFollowed, deleteDataCustomer, getAllProspects} from '../../actions/customerActions';
import {getAllUsers} from "../../actions/userActions";
import ProspectTableComponent from "../../components/Prospect/ProspectTableComponent";


 class ProspectContainer extends Component {

     state = {
         msg: null
     }
     static propTypes = {
         getAllProspects: PropTypes.func.isRequired,
         getAllProspectsFollowed: PropTypes.func.isRequired,
         isAuthenticated: PropTypes.bool,
     }

     componentDidMount() {
         this.props.getAllProspects();
         this.props.getAllProspectsFollowed();
         this.props.getAllUsers();
         this.props.deleteDataCustomer();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <ProspectTableComponent isProspects = {true}/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllProspects: state.customers.getAllProspects,
    getAllProspectsFollowed: state.customers.getAllProspectsFollowed,

    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllProspects, getAllProspectsFollowed, getAllUsers, deleteDataCustomer})(ProspectContainer)