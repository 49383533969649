import React, {Component} from 'react';
import CustomerTableComponent from "../../components/Customers/CustomerTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllCustomers, getAllProspectsRSFollowed, deleteDataCustomer, getAllProspectsRS} from '../../actions/customerActions';
import {getAllUsers} from "../../actions/userActions";
import ProspectTableComponent from "../../components/Prospect/ProspectTableComponent";


 class ProspectRSContainer extends Component {

     state = {
         msg: null
     }
     static propTypes = {
         getAllProspectsRS: PropTypes.func.isRequired,
         getAllProspectsRSFollowed: PropTypes.func.isRequired,
         isAuthenticated: PropTypes.bool,
     }

     componentDidMount() {
         this.props.getAllProspectsRS();
         this.props.getAllProspectsRSFollowed();
         this.props.getAllUsers();
         this.props.deleteDataCustomer();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <ProspectTableComponent isProspectsRS = {true}/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllProspectsRS: state.customers.getAllProspectsRS,
    getAllProspectsRSFollowed: state.customers.getAllProspectsRSFollowed,

    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllProspectsRS, getAllProspectsRSFollowed, getAllUsers, deleteDataCustomer})(ProspectRSContainer)