import React, {Component} from 'react';
import { Container} from "reactstrap";
import BackComponent from "../../components/BackComponent";
import { connect } from "react-redux";
import {deleteDataTask, getAllTasksOfCustomer} from "../../actions/taskActions";
import DetailCustomerComponent from "../../components/Customers/DetailCustomerComponent";
import {getCustomerDetails} from "../../actions/customerActions";
import {getAllDocumentsOfCustomer} from "../../actions/documentActions";
import {getAllCommentsOfCustomer} from "../../actions/commentActions";
import DetailProspectComponent from "../../components/Prospect/DetailProspectComponent";


const mapStateToProps = (state) => {
    return{
        getCustomerDetail: state.customers.getCustomerDetail,
        errorCustomerDetail: state.customers.errorCustomerDetail,
    }
}

 class DetailProspectContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getCustomerDetails(this.props.match.params.id));
         this.props.dispatch(getAllTasksOfCustomer(this.props.match.params.id));
         this.props.dispatch(getAllDocumentsOfCustomer(this.props.match.params.id));
         this.props.dispatch(getAllCommentsOfCustomer(this.props.match.params.id));
         this.props.dispatch(deleteDataTask());
     }

     render() {
        return (
            <Container>
                <BackComponent />
                <h1>Détails du prospect</h1>
                <DetailProspectComponent />
            </Container>
        )
    }
}

export default connect(mapStateToProps, null)(DetailProspectContainer)