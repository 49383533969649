import React, { Component } from "react";
import {reduxForm, Field} from "redux-form";
import {connect} from "react-redux";
import { FormGroup, Input, Button} from "reactstrap";
import CommentValidation from "../validation/CommentValidation";




const mapStateToProps = (state) => {
    let user = JSON.parse(localStorage.getItem('user'));
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        getUserToken: localStorage.getItem('token'),
        getCustomerDetail: state.customers.getCustomerDetail,
        getResponDataComment: state.tasks.getResponDataComment,
        errorResponDataComment: state.tasks.errorResponDataComment,
    };
};

const renderFieldInput = ({
                              input,
                              type,
                              placeholder,
                              disabled,
                              readOnly,
                              meta: { touched, error, warning },
                          }) => (
    <div>
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            />
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
    </div>
);

 class CommentForm extends Component {

    render() {
        return (
                <form onSubmit={this.props.handleSubmit}>
                        <div className="form-group">
                            <FormGroup>
                                <Field
                                    type="textarea"
                                    component={renderFieldInput}
                                    placeholder="Votre commentaire"
                                    name="message"/>
                            </FormGroup>
                        </div>

                        <div className="form-group">
                            <Button
                                color="primary"
                                type="submit"
                                disabled={this.props.submitting}
                            >
                                Commenter ➤
                            </Button>
                        </div>
                </form>
        );
    }
}

CommentForm = reduxForm({
    form: 'formCreateComment',
    validate: CommentValidation,
    enableReinitialize: true,
})(CommentForm);

export default connect(mapStateToProps, null)(CommentForm)