import React, {Component} from 'react';
import {Container} from "@material-ui/core";
import BackComponent from "../../components/BackComponent";
import {connect} from 'react-redux';
import {getAllUsers} from "../../actions/userActions";
import { getAllCustomers, getCustomerDetails} from "../../actions/customerActions";
import Swal from 'sweetalert2';
import {addDocument, getAllDocumentsOfCustomer} from "../../actions/documentActions";
import DocumentFormComponent from "../../components/DocumentFormComponent";

const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getResponDataDocument: state.customers.getResponDataDocument,
        errorResponDataDocument: state.customers.errorResponDataDocument,

    };
};

 class CreateDocumentContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getAllUsers());
         this.props.dispatch(getAllCustomers());
         this.props.dispatch(getCustomerDetails(this.props.match.params.id));
         this.props.dispatch(getAllDocumentsOfCustomer(this.props.match.params.id));
     }

    handleSubmit(data){
     {this.props.getAllUsers.map((user) => {
                if(user._id == data.user_id){
                    data.user_firstname = user.firstname
                    data.user_lastname = user.lastname
                }
            }
        )}
        this.props.dispatch(addDocument(data))
    }

     render() {
         if(this.props.getResponDataDocument || this.props.errorResponDataDocument) {
             if (this.props.errorResponDataDocument) {
                 Swal.fire(
                     "Echec ! ",
                     this.props.errorResponDataDocument,
                     "error"
                 );
             } else {
                 Swal.fire({
                         title: "Document crée !",
                     icon :"success"
                 }).then((result) => {
                     /* Read more about isConfirmed, isDenied below */
                     if (result.isConfirmed) {
                         window.history.back();
                     }
                 })
             }
         }
         return (
            <Container>
                <BackComponent />
                <h1>Ajouter un document</h1>
                <DocumentFormComponent />
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(CreateDocumentContainer)