import React, {Component} from 'react';
import {
    BrowserRouter,
    Route,
} from "react-router-dom";
import AppNavbar from "./components/AppNavbar";
import {Container} from "reactstrap";
import store from './store';
import {loadUser} from "./actions/authActions";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './App.scss';
import PropTypes from "prop-types";
import TaskContainer from "./containers/Tasks/TaskContainer";
import CreateTaskContainer from "./containers/Tasks/CreateTaskContainer";
import DetailTaskContainer from "./containers/Tasks/DetailTaskContainer";
import EditTaskContainer from "./containers/Tasks/EditTaskContainer";
import CustomerContainer from "./containers/Customers/CustomerContainer";
import CreateCustomerContainer from "./containers/Customers/CreateCustomerContainer";
import EditCustomerContainer from "./containers/Customers/EditCustomerContainer";
import DetailCustomerContainer from "./containers/Customers/DetailCustomerContainer";
import UserContainer from "./containers/Users/UserContainer";
import CreateUserContainer from "./containers/Users/CreateUserContainer";
import DetailUserContainer from "./containers/Users/DetailUserContainer";
import EditUserContainer from "./containers/Users/EditUserContainer";
import CreateDocumentContainer from "./containers/Documents/CreateDocumentContainer";
import MetaTags from 'react-meta-tags';
import ProspectContainer from "./containers/Prospects/ProspectContainer";
import ProspectMailContainer from "./containers/ProspectsMail/ProspectMailContainer";
import ProspectRSContainer from "./containers/ProspectsRS/ProspectRSContainer";
import CreateProspectContainer from "./containers/Prospects/CreateProspectContainer";
import DetailProspectContainer from "./containers/Prospects/DetailProspectContainer";
import EditProspectContainer from "./containers/Prospects/EditProspectContainer";
import BillingContainer from "./containers/Billings/BillingContainer";
import CreateBillingContainer from "./containers/Billings/CreateBillingContainer";
import DetailBillingContainer from "./containers/Billings/DetailBillingContainer";
import EditBillingContainer from "./containers/Billings/EditBillingContainer";
import OlderCustomerContainer from "./containers/Older_Customers/OlderCustomerContainer";
import CreateOlderCustomerContainer from "./containers/Older_Customers/CreateOlderCustomerContainer";
import DetailOlderCustomerContainer from "./containers/Older_Customers/DetailOlderCustomerContainer";
import EditOlderCustomerContainer from "./containers/Older_Customers/EditOlderCustomerContainer";
import CreateProspectMailContainer from "./containers/ProspectsMail/CreateProspectMailContainer";
import CreateProspectRSContainer from "./containers/ProspectsRS/CreateProspectRSContainer";
import DetailProspectMailContainer from "./containers/ProspectsMail/DetailProspectMailContainer";
import DetailProspectRSContainer from "./containers/ProspectsRS/DetailProspectRSContainer";
import EditProspectMailContainer from "./containers/ProspectsMail/EditProspectMailContainer";
import EditProspectRSContainer from "./containers/ProspectsRS/EditProspectRSContainer";


class App extends Component {


    static propTypes = {
        isAuthenticated: PropTypes.bool
    }

    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
       const user = JSON.parse(localStorage.getItem('user'))
        return (
            <BrowserRouter>
                <div className="App">
                    <MetaTags>
                        <meta name="google" content="notranslate"/>
                    </MetaTags>
                    <AppNavbar/>
                    <Container>
                        {
                            /*
                            <Route path="/" exact component={TaskContainer}>
                            </Route>
                             */
                        }
                        {
                            user?.role === 'admin' ?

                                <Route path="/" exact component={CustomerContainer}>
                                </Route>
                                :
                                <Route path="/" exact component={ProspectContainer}>
                                </Route>
                        }
                            <Route path="/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/billingList" exact component={BillingContainer}>
                            </Route>
                            <Route path="/createBilling" component={CreateBillingContainer}>
                            </Route>
                            <Route path="/detailBilling/:id" exact component={DetailBillingContainer}>
                            </Route>
                            <Route path="/editBilling/:id" exact component={EditBillingContainer}>
                            </Route>
                            <Route path="/customerList" exact component={CustomerContainer}>
                            </Route>
                            <Route path="/createCustomer" exact component={CreateCustomerContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id" exact component={DetailCustomerContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/detailCustomer/:id/createDocument"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/editCustomer/:id" exact component={EditCustomerContainer}>
                            </Route>
                            <Route path="/olderCustomerList" exact component={OlderCustomerContainer}>
                            </Route>
                            <Route path="/createOlderCustomer" exact component={CreateOlderCustomerContainer}>
                            </Route>
                            <Route path="/detailOlderCustomer/:id" exact component={DetailOlderCustomerContainer}>
                            </Route>
                            <Route path="/detailOlderCustomer/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailOlderCustomer/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailOlderCustomer/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/detailOlderCustomer/:id/createDocument"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/editOlderCustomer/:id" exact component={EditOlderCustomerContainer}>
                            </Route>
                            <Route path="/createDocument/:id"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/prospectList" exact component={ProspectContainer}>
                            </Route>
                            <Route path="/prospectListMail" exact component={ProspectMailContainer}>
                            </Route>
                            <Route path="/prospectListRS" exact component={ProspectRSContainer}>
                            </Route>
                            <Route path="/createProspect" exact component={CreateProspectContainer}>
                            </Route>
                            <Route path="/createProspectMail" exact component={CreateProspectMailContainer}>
                            </Route>
                            <Route path="/createProspectRS" exact component={CreateProspectRSContainer}>
                            </Route>
                            <Route path="/detailProspect/:id" exact component={DetailProspectContainer}>
                            </Route>
                            <Route path="/detailProspectMail/:id" exact component={DetailProspectMailContainer}>
                            </Route>
                            <Route path="/detailProspectRS/:id" exact component={DetailProspectRSContainer}>
                            </Route>
                            <Route path="/detailProspect/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailProspectMail/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailProspectRS/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailProspect/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailProspectMail/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailProspectRS/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailProspect/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/detailProspectMail/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/detailProspectRS/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/detailProspect/:id/createDocument"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/detailProspectMail/:id/createDocument"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/detailProspectRS/:id/createDocument"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/createDocument/:id"  component={CreateDocumentContainer}>
                            </Route>
                            <Route path="/editProspect/:id" exact component={EditProspectContainer}>
                            </Route>
                            <Route path="/editProspectMail/:id" exact component={EditProspectMailContainer}>
                            </Route>
                            <Route path="/editProspectRS/:id" exact component={EditProspectRSContainer}>
                            </Route>
                            <Route path="/userList" exact component={UserContainer}>
                            </Route>
                            <Route path="/createUser" exact component={CreateUserContainer}>
                            </Route>
                            <Route path="/detailUser/:id" exact component={DetailUserContainer}>
                            </Route>
                            <Route path="/detailUser/:id/createTask" component={CreateTaskContainer}>
                            </Route>
                            <Route path="/detailUser/:id/detailTask/:id" exact component={DetailTaskContainer}>
                            </Route>
                            <Route path="/detailUser/:id/editTask/:id" exact component={EditTaskContainer}>
                            </Route>
                            <Route path="/editUser/:id" exact component={EditUserContainer}>
                            </Route>
                    </Container>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
