import React, {Component} from 'react';
import {Container} from "@material-ui/core";
import BackComponent from "../../components/BackComponent";
import {connect} from 'react-redux';
import {addUser, deleteDataUser, getAllUsers} from "../../actions/userActions";
import {getAllCustomers} from "../../actions/customerActions";
import Swal from 'sweetalert2';
import {Link, Redirect} from "react-router-dom";
import UserFormComponent from "../../components/User/UserFormComponent";

const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getResponDataUser: state.users.getResponDataUser,
        errorResponDataUser: state.users.errorResponDataUser,
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        isAuthenticated: state.auth.isAuthenticated

    };
};

 class CreateUserContainer extends Component {

     componentDidMount() {
         this.props.dispatch(getAllUsers());
         this.props.dispatch(getAllCustomers());
     }

    handleSubmit(data){
     {this.props.getAllUsers.map((user) => {
                if(user._id == data.user_id){
                    data.user_firstname = user.firstname
                    data.user_lastname = user.lastname
                }
            }
        )}
        data.firstname ? data.firstname = data.firstname.toUpperCase() : null;
         data.lastname ? data.lastname = data.lastname.toUpperCase(): null;
         data.email ? data.email = data.email.toLowerCase(): null;
        this.props.dispatch(addUser(data))
    }

     render() {
         if(this.props.getResponDataUser || this.props.errorResponDataUser) {
             if (this.props.errorResponDataUser) {
                 Swal.fire(
                     "Echec ! ",
                     this.props.errorResponDataUser,
                     "error"
                 ).then((result) => {
                     /* Read more about isConfirmed, isDenied below */
                     if (result.isConfirmed) {
                     this.props.dispatch(deleteDataUser());
                }
             })} else {
                 Swal.fire({
                     title: "Utilisateur crée !",
                     icon :"success"
                 }).then((result) => {
                     /* Read more about isConfirmed, isDenied below */
                     if (result.isConfirmed) {
                         window.history.back();
                     }
                 })
             }
         }
         return (
            <Container>
                <div>
                    {this.props.isAuthenticated && this.props.getUserConnected.role === 'admin' &&
                    <div>
                        <BackComponent />
                        <h1>Crée un utilisateur</h1>
                        <UserFormComponent onSubmit={(data) => this.handleSubmit(data)}/>
                    </div>
                    }
                    {this.props.getUserConnected.role !== 'admin' &&
                    <div>
                        <h2>Vous ne possedez pas les accés pour accéder à cette page</h2>
                        <Link to='/'/>
                    </div>
                    }
                    {!this.props.isAuthenticated &&
                    <div>
                        <h2>Veuillez vous connecter pour accéder au CRM</h2>
                    </div>
                    }
                </div>
            </Container>
        )
    }
}

export default connect(mapStateToProps,null)(CreateUserContainer)