import React from 'react';
import {Row, Col, Button} from 'reactstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

const BackComponent = () => {
    let history = useHistory();
    return (

        <Row className="mb-2">
            <Col>
                    <Button onClick={() => history.goBack()} color="secondary">
                        <FontAwesomeIcon icon={faArrowLeft}/> Retour
                    </Button>
            </Col>
        </Row>
    )
}

export default BackComponent;