import {
    GET_ALL_TASKS,
    GET_TASK_DETAILS,
    ADD_TASK,
    UPDATE_TASK,
    DELETE_TASK,
    TASKS_LOADING,
    GET_ALL_TASKS_OF_TODAY,
    GET_ALL_TASKS_OF_CUSTOMER,
    GET_ALL_TASKS_OF_USER, GET_ALL_TASKS_OF_TODAY_OF_USER
} from "../actions/types";
import moment from "moment";

const initialState = {
    tasks: [],
    getAllTasks: false,
    getAllTasksOfToday: false,
    getAllTasksOfTodayOfUser: false,
    getAllTasksOfCustomer: false,
    getAllTasksOfUser: false,
    getTaskDetail: false,
    getCustomerDetail: false,
    getResponDataTask: false,
    errorResponDataTask: false,
    errorTaskDetail: false,
    loading: false,
    errorTasksList: false
}

const tasks = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_TASKS:
            return {
                ...state,
                getAllTasks: action.payload.data,
                loading: false
            };
        case GET_ALL_TASKS_OF_TODAY:
            return {
                ...state,
                getAllTasksOfToday: action.payload.data,
                loading: false
            };
            case GET_ALL_TASKS_OF_TODAY_OF_USER:
            return {
                ...state,
                getAllTasksOfTodayOfUser: action.payload.data,
                loading: false
            };
        case GET_ALL_TASKS_OF_CUSTOMER:
            return {
                ...state,
                getAllTasksOfCustomer: action.payload.data,
                loading: false
            };
        case GET_ALL_TASKS_OF_USER:
            return {
                ...state,
                getAllTasksOfUser: action.payload.data,
                loading: false
            };
        case GET_TASK_DETAILS:
            return {
                ...state,
                getTaskDetail: action.payload.data,
                errorTaskDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_TASK:
            return {
                ...state,
                tasks: state.tasks.filter(task => task._id !== action.payload),
                loading: false
            };
        case ADD_TASK:
            return {
                ...state,
                getResponDataTask: action.payload.data,
                errorResponDataTask: action.payload.errorMessage,
                loading: false
            };
        case UPDATE_TASK:
            return {
                ...state,
                getResponDataTask: action.payload.data,
                errorResponDataTask: action.payload.errorMessage
            }
        case TASKS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default tasks