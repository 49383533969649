import {
    GET_ALL_CUSTOMERS,
    GET_ALL_PROSPECTS_FOLLOWED,
    GET_ALL_PROSPECTS_MAIL,
    GET_ALL_PROSPECTS_MAIL_FOLLOWED,
    GET_ALL_PROSPECTS_RS,
    GET_ALL_PROSPECTS_RS_FOLLOWED,
    GET_CUSTOMER_DETAILS,
    ADD_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    CUSTOMERS_LOADING, GET_ALL_OLDER_CUSTOMERS,
} from "../actions/types";

const initialState = {
    customers: [],
    getAllCustomers: false,
    getAllOlderCustomers: false,
    getAllProspectsFollowed: false,
    getAllProspectsMail: false,
    getAllProspectsMailFollowed: false,
    getAllProspectsRS: false,
    getAllProspectsRSFollowed: false,
    getCustomerDetail: false,
    getResponDataCustomer: false,
    errorResponDataCustomer: false,
    errorCustomerDetail: false,
    loading: false,
    errorCustomersList: false
}

const customers = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CUSTOMERS:
            localStorage.setItem('allCustomers', JSON.stringify(action.payload.data));
            localStorage.setItem('isOlderCustomers','false');
            return {
                ...state,
                getAllCustomers: action.payload.data,
                loading: false
            };
        case GET_ALL_OLDER_CUSTOMERS:
            localStorage.setItem('allOlderCustomers', JSON.stringify(action.payload.data));
            localStorage.setItem('isOlderCustomers','true');
            return {
                ...state,
                getAllOlderCustomers: action.payload.data,
                loading: false
            };
        case GET_ALL_PROSPECTS_FOLLOWED:
            localStorage.setItem('allProspectsFollowed', JSON.stringify(action.payload.data));
            localStorage.setItem('prospect','null');
            localStorage.setItem('followed','true');
            return {
                ...state,
                getAllProspectsFollowed: action.payload.data,
                loading: false
            };
        case GET_ALL_PROSPECTS_MAIL:
            localStorage.setItem('allProspectsMail', JSON.stringify(action.payload.data));
            localStorage.setItem('prospect','MAIL');
            localStorage.setItem('followed','false');
            return {
                ...state,
                getAllProspectsMail: action.payload.data,
                loading: false
            };
        case GET_ALL_PROSPECTS_MAIL_FOLLOWED:
            localStorage.setItem('allProspectsMailFollowed', JSON.stringify(action.payload.data));
            localStorage.setItem('prospect','MAIL');
            localStorage.setItem('followed','true');
            return {
                ...state,
                getAllProspectsMailFollowed: action.payload.data,
                loading: false
            };
        case GET_ALL_PROSPECTS_RS:
            localStorage.setItem('allProspectsRS', JSON.stringify(action.payload.data));
            localStorage.setItem('prospect','RS');
            localStorage.setItem('followed','false');
            return {
                ...state,
                getAllProspectsRS: action.payload.data,
                loading: false
            };
        case GET_ALL_PROSPECTS_RS_FOLLOWED:
            localStorage.setItem('allProspectsRSFollowed', JSON.stringify(action.payload.data));
            localStorage.setItem('prospect','RS');
            localStorage.setItem('followed','true');
            return {
                ...state,
                getAllProspectsRSFollowed: action.payload.data,
                loading: false
            };
        case GET_CUSTOMER_DETAILS:
            return {
                ...state,
                getCustomerDetail: action.payload.data,
                errorCustomerDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.filter(customer => customer._id !== action.payload),
                loading: false
            };
        case ADD_CUSTOMER:
            return {
                ...state,
                getResponDataCustomer: action.payload.data,
                errorResponDataCustomer: action.payload.errorMessage,
                loading: false
            };
        case UPDATE_CUSTOMER:
            return {
                ...state,
                getResponDataCustomer: action.payload.data,
                errorResponDataCustomer: action.payload.errorMessage,
                loading: false
            }
        case CUSTOMERS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default customers