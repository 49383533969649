import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Container, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faTasks, faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import Moment from "react-moment";
import moment from "moment";
import Swal from "sweetalert2";
import  { Link, Redirect } from 'react-router-dom';
import {isMobile} from 'react-device-detect'
import {deleteUser, getAllUsers} from "../../actions/userActions";
if (isMobile) { require('../customer.scss'); }

moment.locale('fr');


const {SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const handleClick = (dispatch, id) => {

    Swal.fire({
        title: "Êtes vous sur de vouloir supprimer cet utilisateur ?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        denyButtonText: `Annuler`,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete.isConfirmed) {
                dispatch(deleteUser(id))
                Swal.fire({
                    title: "Utilisateur supprimé avec succés",
                    text: "",
                    icon: "success"})
                    .then((result) => {
                        if(result.isConfirmed){
                            dispatch(getAllUsers());
                        }
                    });
            } else {
                Swal.fire("L'utilisateur est sauvegardé", "","info");
            }
        });
}



const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}]

const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers,
        isAuthenticated: state.auth.isAuthenticated,
        errorUsersList: state.users.errorUsersList,
        errorTasksList: state.tasks.errorTasksList,
        loading: state.users.loading
    }
}

const UserTableComponent = (props) => {

    const user = JSON.parse(localStorage.getItem('user'));

    if(!props.isAuthenticated){
        return <Link to="/"/>
    }

    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        sizePerPageList: [{
            text: '50', value: 50
        },{
            text: '30', value: 30
        },{
            text: '25', value: 25
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "8%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: '_id',
            text: 'ID',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: 'firstname',
            text: 'Prenom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'lastname',
            text: 'Nom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"mailto:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'phone',
            text: 'Téléphone',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"tel:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'register_date',
            text: 'Enregistré le',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                const date = moment(rowContent).format('L');
                return (
                    date
                )
            }
        },
        {
            dataField: 'role',
            text: 'Role',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if(rowContent === 'admin'){
                    return (
                        "Admin"
                    )
                }else if(rowContent === 'commercial'){
                    return (
                        "Commercial"
                    )
                } else if(rowContent === 'gestionnaire_client'){
                    return (
                        "Gestionnaire client"
                    )
                }
            },
            csvFormatter: (rowContent, row) => {
                if(rowContent === 'admin'){
                    return (
                        "Admin"
                    )
                }else if(rowContent === 'commercial'){
                    return (
                        "Commercial"
                    )
                } else if(rowContent === 'gestionnaire_client'){
                    return (
                        "Gestionnaire client"
                    )
                }
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: "link",
            text: "Action",
            csvExport: false,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "16%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
            formatter: (rowContent, row) => {
                const user = JSON.parse(localStorage.getItem('user'));
                return (
                    <div>
                        <Link to={"detailUser/" + row._id}>
                            <Button color="info" className="mr-2">
                                <FontAwesomeIcon icon={faInfo}/>
                            </Button>
                        </Link>
                        <Link to={"editUser/" + row._id}>
                            <Button color="warning" className="mr-2">
                                <FontAwesomeIcon icon={faEdit}/>
                            </Button>
                        </Link>
                        {user?.role === 'admin' ?
                        <Button color="danger" className="mr-2" onClick={() => handleClick(props.dispatch,row._id)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                            : null
                        }
                    </div>
                )
            }
        }
    ];
    return (
    <Container>
        <div>
            <h2>
                Liste des utilisateurs ({props.getAllUsers.length})
            </h2>
        </div>
        {props.getAllUsers ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={props.getAllUsers}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextUser')
                } }
                exportCSV={ {
                    fileName: 'Liste_des_utilisateurs.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                    separator: ";"
                }}
            >
                {(props) => (
                    <div>
                        <Row>
                            {user?.role === 'admin' &&
                                <Col>
                                    <Link to={"createUser"}>
                                        <Button color="primary" className="mr-2">
                                            <FontAwesomeIcon icon={faTasks}/> Ajouter un utilisateur
                                        </Button>
                                    </Link>
                                    {
                                        /*
                                        <ExportCSVButton { ...props.csvProps }  className="mr-2">
                                            <Button color="info" >
                                                Export au format CSV
                                            </Button>
                                        </ExportCSVButton>
                                         */
                                    }
                                </Col>
                            }
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                    {localStorage.setItem('searchTextUser', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable striped
                                        hover
                                        wrapperClasses="table-responsive"
                                        {...props.baseProps} pagination={paginationFactory(options)}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorUsersList ? <h4>{props.errorUsersList}</h4> :
                        <Spinner color="dark"/>
                    }
                </div>
            )}
        <div className="text-center">
            {
                props.loading ? <Spinner color="dark"/> : null
            }
        </div>
    </Container>

)
};


export default connect(mapStateToProps, null)(UserTableComponent);