import React, { Component} from 'react';
import {reduxForm, Field} from 'redux-form';
import { connect} from 'react-redux';
import { FormGroup, Col, Label, Input, Row, Button } from "reactstrap";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment-localizer'
import {Link, Redirect} from 'react-router-dom';
import Select from 'react-select'
import CustomerValidation from "../../validation/CustomerValidation";


momentLocalizer(moment);

const mapStateToProps = (state) => {
    let user = JSON.parse(localStorage.getItem('user'));
    let isOlderCustomers = JSON.parse(localStorage.getItem('isOlderCustomers'));
    console.log(isOlderCustomers)
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getAllOlderCustomers: state.customers.getAllOlderCustomers ? state.customers.getAllOlderCustomers : JSON.parse(localStorage.getItem('allOlderCustomers')),
        getUserConnected: JSON.parse(localStorage.getItem('user')),
        getUserToken: localStorage.getItem('token'),
        getCustomerDetail: state.customers.getCustomerDetail,
        getResponDataCustomer: state.customers.getResponDataCustomer,
        errorResponDataCustomer: state.customers.errorResponDataCustomer,
        initialValues: {
            firstname: state.customers.getCustomerDetail.firstname,
            lastname: state.customers.getCustomerDetail.lastname,
            company_name: state.customers.getCustomerDetail.company_name,
            website_url: state.customers.getCustomerDetail.website_url,
            website_url_iweb: state.customers.getCustomerDetail.website_url_iweb,
            ads_campaign_name: state.customers.getCustomerDetail.ads_campaign_name,
            password_iweb: state.customers.getCustomerDetail.password_iweb,
            email: state.customers.getCustomerDetail.email,
            phone: state.customers.getCustomerDetail.phone,
            membership: state.customers.getCustomerDetail.membership || true,
            older_customer: state.customers.getCustomerDetail.older_customer || isOlderCustomers,
            customer_manager_access: state.customers.getCustomerDetail.customer_manager_access || false,
            subscription_amount: state.customers.getCustomerDetail.subscription_amount,
            /*
            street: state.customers.getCustomerDetail.street,
            city: state.customers.getCustomerDetail.city,
            state: state.customers.getCustomerDetail.state,
            zip: state.customers.getCustomerDetail.zip,
            street_delivery: state.customers.getCustomerDetail.street_delivery,
            city_delivery: state.customers.getCustomerDetail.city_delivery,
            state_delivery: state.customers.getCustomerDetail.state_delivery,
            zip_delivery: state.customers.getCustomerDetail.zip_delivery,
            type: state.customers.getCustomerDetail.type || 'Centre dentaire',
            siret: state.customers.getCustomerDetail.siret,
            nic: state.customers.getCustomerDetail.nic,
            customer_id: state.customers.getCustomerDetail.customer_id ? {
                label: state.customers.getCustomerDetail.customer_firstname ?
                    state.customers.getCustomerDetail.customer_firstname : '' + ' ' +
                    state.customers.getCustomerDetail.customer_lastname ? state.customers.getCustomerDetail.customer_lastname : '',
                value : state.customers.getCustomerDetail.customer_id
            }: null,
            customer_firstname: state.customers.getCustomerDetail.customer_firstname,
            customer_lastname: state.customers.getCustomerDetail.customer_lastname,
             */
            user_id: state.customers.getCustomerDetail.user_id ? {
                label: (state.customers.getCustomerDetail.user_firstname ?
                    state.customers.getCustomerDetail.user_firstname : '') + ' ' +
                    (state.customers.getCustomerDetail.user_lastname ? state.customers.getCustomerDetail.user_lastname : ''),
                value : state.customers.getCustomerDetail.user_id
            }: user.id ? {
                label: (user.firstname ? user.firstname : '') + ' ' + (user.lastname ? user.lastname : ''),
                value : user.id
            }: null,
            user_firstname: state.customers.getCustomerDetail.user_firstname || user.firstname,
            user_lastname: state.customers.getCustomerDetail.user_lastname || user.lastname,

        }
    };
};

const renderFieldInput = ({
                              input,
                              type,
                              placeholder,
                              label,
                              disabled,
                              readOnly,
                              meta: { touched, error, warning },
                          }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            />
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);

const renderFieldSelectMembership = ({
                                         input,
                                         type,
                                         placeholder,
                                         label,
                                         disabled,
                                         readOnly,
                                         meta: { touched, error, warning },
                                     }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            >
                <option value={true}>Client</option>
                <option value={false}>Prospect</option>
            </Input>
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);

const renderFieldSelectOlderCustomer = ({
                                         input,
                                         type,
                                         placeholder,
                                         label,
                                         disabled,
                                         readOnly,
                                         meta: { touched, error, warning },
                                     }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            >
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
            </Input>
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);

const renderFieldSelectUserId = ({
                                     input,
                                     name,
                                     placeholder,
                                     label,
                                     options,
                                     id,
                                     meta: { touched, error, warning },
                                 }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Select
                placeholder={placeholder}
                {...input}
                //id={id}
                //name={name}
                options={options}
                //value={input.value}
                onChange={(value) => input.onChange(value)}
                onBlur={() => input.onBlur()}
                isClearable
                isSearchable
                //onChange={value => input.onChange(value)}
                //value={options.filter(option => option.value === this.props.getCustomerDetail.customer_id)}
                //options={options}
            />
            {touched &&
            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);

const renderFieldSelectCustomerManagerAccess = ({
                                         input,
                                         type,
                                         placeholder,
                                         label,
                                         disabled,
                                         readOnly,
                                         meta: { touched, error, warning },
                                     }) => (
    <Row>
        <Col md="12">
            <Label htmlFor="{input}" className="col-form-label">
                {label}
            </Label>
        </Col>
        <Col md="12">
            <Input
                {...input}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            >
                <option value={true}>Oui</option>
                <option value={false}>Non</option>
            </Input>
            {touched &&
                ((error && <p style={{ color: "red" }}>{error}</p>) ||
                    (warning && <p style={{ color: "brown" }}>{warning}</p>))}
        </Col>
    </Row>
);
class CustomerFormComponent extends Component {

    render()
    {
        if(!this.props.getUserConnected || !this.props.getUserToken){
            return <Link to="/"/>
        }


        let userId = [];

        this.props.getAllUsers.map((user) => {
            userId.push({
                label: (user.firstname ? user.firstname : '') + ' ' +  (user.lastname ? user.lastname : ''),
                value: user._id
            })})

        /*
        const renderFieldSelectCustomerId = ({
                                                         label,
                                                         options,
                                                         input,
                                                         placeholder,
                                                         id,
                                                         name,
                                                         defaultValue,
                                                         meta: { touched, error, warning },
                                                     }) => (

                    <Row>
                        <Col md="12">
                            <Label htmlFor="{input}" className="col-form-label">
                                {label}
                            </Label>
                        </Col>

                        <Col md="12">
                            <Select
                                placeholder={placeholder}
                                {...input}
                                //id={id}
                                //name={name}
                                options={options}
                                //value={input.value}
                                onChange={(value) => input.onChange(value)}
                                onBlur={() => input.onBlur()}
                                isClearable
                                isSearchable
                                //onChange={value => input.onChange(value)}
                                //value={options.filter(option => option.value === this.props.getCustomerDetail.customer_id)}
                                //options={options}
                            />
                            {touched &&
                            ((error && <p style={{ color: "red" }}>{error}</p>) ||
                                (warning && <p style={{ color: "brown" }}>{warning}</p>))}
                        </Col>
                    </Row>
                );
             */


        let customerId = [];

         this.props.getAllCustomers.map((customer) => {
             customerId.push({
                 label: (customer.firstname ? customer.firstname : '') + ' ' +  (customer.lastname ? customer.lastname : ''),
                 value: customer._id
             })})


        return (
            <form onSubmit={this.props.handleSubmit}>
                <FormGroup row>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="lastname"
                                label="Nom : "
                                placeholder="Nom du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="firstname"
                                label="Prenom : "
                                placeholder="Prenom du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="email"
                                label="Email : "
                                placeholder="Email du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="company_name"
                                label="Nom de société : "
                                placeholder="Nom de société"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="website_url"
                                label="Site Web du client : "
                                placeholder="Site Web du client"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="website_url_iweb"
                                label="Url iweb du client : "
                                placeholder="Url iweb du client"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="ads_campaign_name"
                                label="Nom de la campagne du client : "
                                placeholder="Nom de la campagne du client"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="password_iweb"
                                label="Mot de passe iweb du client : "
                                placeholder="Mot de passe iweb du client"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="number"
                                component={renderFieldInput}
                                name="phone"
                                label="Téléphone : "
                                placeholder="Téléphone du client"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="select"
                                component={renderFieldSelectMembership}
                                label="Client ou prospect : "
                                name="membership"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="select"
                                component={renderFieldSelectOlderCustomer}
                                label="Ancien client : "
                                name="older_customer"/>
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="subscription_amount"
                                label="Montant de l'abonnement"
                                placeholder="Montant de l'abonnement"
                            />
                        </FormGroup>
                    </Col>

                    {
                        // Champs qu'on veut caché
                        /*
                        <Col md="4">
                        <FormGroup>
                            <Field
                                type="number"
                                component={renderFieldInput}
                                name="portable"
                                label="Portable : "
                                placeholder="Portable du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="street"
                                label="Rue : "
                                placeholder="Rue du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="zip"
                                label="Code postal : "
                                placeholder="Code postal du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="city"
                                label="Ville : "
                                placeholder="Ville du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="state"
                                label="Pays : "
                                placeholder="Pays du client"/>
                        </FormGroup>
                    </Col>


                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="street_delivery"
                                label="Rue de livraison: "
                                placeholder="Rue de livraison du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="zip_delivery"
                                label="Code postal de livraison: "
                                placeholder="Code postal de livraison du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="city_delivery"
                                label="Ville de livraison : "
                                placeholder="Ville de livraison du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="text"
                                component={renderFieldInput}
                                name="state_delivery"
                                label="Pays de livraison: "
                                placeholder="Pays de livraison du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                type="number"
                                component={renderFieldInput}
                                name="siret"
                                label="N° de siret: "
                                placeholder="N° de siret du client"/>
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup>
                            <Field
                                component={renderFieldSelectCustomerId}
                                placeholder="Selectionner un client"
                                label="Client rattaché: "
                                options={customerId}
                                name="customer_id"/>
                        </FormGroup>
                    </Col>

                         */
                    }
                    {
                        this.props.getUserConnected.role === 'admin' ?
                            <Col md="4">
                                <FormGroup>
                                    <Field
                                        component={renderFieldSelectUserId}
                                        label="Suivi par l'utilisateur :"
                                        name="user_id"
                                        options={userId}
                                        defaultValue={this.props.getUserConnected.id}/>
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        type="select"
                                        component={renderFieldSelectCustomerManagerAccess}
                                        label="Acces Gestionnaire Client : "
                                        name="customer_manager_access"/>
                                </FormGroup>
                            </Col>
                        : null
                    }
                </FormGroup>

                <FormGroup row className="my-0">
                    <Col md="12">
                        <FormGroup>
                            <Button
                                color="primary"
                                type="submit"
                                disabled={this.props.submitting}
                            >
                                Valider
                            </Button>
                        </FormGroup>
                    </Col>
                </FormGroup>
            </form>
        );
    }
}

  CustomerFormComponent = reduxForm({
    form: 'formCreateCustomer',
    //validate: CustomerValidation,
    enableReinitialize: true,
})(CustomerFormComponent);

export default connect(mapStateToProps, null)(CustomerFormComponent)