import React from 'react'
import { connect } from 'react-redux';
import {Table} from "reactstrap";
import moment from "moment";

moment.locale('fr');


const mapStateToProps = (state) => {
    return{
        getBillingDetail: state.billings.getBillingDetail,
        errorBillingDetail: state.billings.errorBillingDetail,
    }
}

let DetailBillingComponent = (props) => {
    return (
        <Table striped>
            <tbody>
                <tr>
                    <td width="200">
                        Nom
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {
                            props.getBillingDetail.type
                                ? props.getBillingDetail.type
                                : 'Aucun type'
                        }
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Description
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {
                            props.getBillingDetail.description
                            ? props.getBillingDetail.description
                            : 'Aucune description'
                        }
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Montant
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {
                            props.getBillingDetail.amount
                                ? props.getBillingDetail.amount
                                : 'Aucun montant défini'
                        }
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Prélévement le :
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {moment(props.getBillingDetail.date).format('L')}
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Status
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {props.getBillingDetail.status == 'paye' ? 'Payé' :
                            props.getBillingDetail.status == 'en_attente' ? 'En attente' :
                                props.getBillingDetail.status == 'annule' ? 'Annulé' :
                                    props.getBillingDetail.status == 'en_veille' ? 'En veille' : 'Aucun status'
                        }
                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Responsable
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {props.getBillingDetail.user_firstname ?
                            props.getBillingDetail.user_firstname : 'Non défini'}

                        {props.getBillingDetail.user_lastname ?
                            ' ' + props.getBillingDetail.user_lastname : null}

                    </td>
                </tr>
                <tr>
                    <td width="200">
                        Pour le client
                    </td>
                    <td width="10"> : </td>
                    <td>
                        {props.getBillingDetail.customer_firstname ?
                            props.getBillingDetail.customer_firstname : 'Non défini'}

                        {props.getBillingDetail.customer_lastname ?
                            ' ' + props.getBillingDetail.customer_lastname : null}

                    </td>
                </tr>
            </tbody>
        </Table>
    )
}


export default connect(mapStateToProps, null)(DetailBillingComponent)