const BillingValidation = (values) => {
    const errors = {};
    /*
    if(!values.name || values.name === ""){
        errors.name = "Le nom est obligatoire"
    }
     */
    if(!values.date || values.date === ""){
        errors.date = "La date est obligatoire"
    }
    /*
    if(!values.user_id || values.user_id === ""){
        errors.user_id = "Assigner la tâche à un utilisateur est obligatoire"
    }
     */
    if(!values.customer_id || values.customer_id === ""){
        errors.customer_id = "Relier la facture à un client est obligatoire"
    }
    return errors
};

export default BillingValidation;