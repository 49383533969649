import React, { Component} from 'react'
import { connect } from 'react-redux';
import {Button, Container, Spinner, Table} from "reactstrap";
import Moment from "react-moment";
import moment from "moment";
import {Search} from "react-bootstrap-table2-toolkit";
import Swal from "sweetalert2";
import {deleteTask} from "../../actions/taskActions";
import TaskCustomerTableComponent from "./TaskCustomerTableComponent";
import DocumentCustomerTableComponent from "./DocumentCustomerTableComponent";
import CommentList from "../CommentList";
import CommentForm from "../CommentForm";
import {addComment, getAllCommentsOfCustomer} from "../../actions/commentActions";

moment.locale('fr');

const mapStateToProps = (state) => {
    return{
        getCustomerDetail: state.customers.getCustomerDetail,
        getAllTasksOfCustomer: state.tasks.getAllTasksOfCustomer,
        getAllDocumentsOfCustomer: state.documents.getAllDocumentsOfCustomer,
        getAllCommentsOfCustomer: state.comments.getAllCommentsOfCustomer,
        errorCustomerDetail: state.customers.errorCustomerDetail,
        isAuthenticated: state.auth.isAuthenticated,
        errorTasksList: state.tasks.errorTasksList,
        loading: state.comments.loading
    }
}


class DetailCustomerComponent extends Component {

    handleSubmit(data){
        const user = JSON.parse(localStorage.getItem("user"));
        data.date = new Date().toISOString();
        data.user_id = user.id;
        data.user_firstname = user.firstname;
        data.user_lastname = user.lastname;
        data.customer_id = this.props.getCustomerDetail._id;
        data.customer_firstname = this.props.getCustomerDetail.firstname;
        data.customer_lastname= this.props.getCustomerDetail.lastname;

        this.props.dispatch(addComment(data))
            .then(() => {
                setTimeout(() => {
                    this.props.dispatch(getAllCommentsOfCustomer(this.props.getCustomerDetail._id));
                }, 1000)
        })

    }

    render() {
        return (
            <div>
                <Table striped bordered responsive variant="dark">
                    <thead>
                    <tr>
                        <th>Nom</th>
                        <th>Prenom</th>
                        <th>Nom de société</th>
                        <th>Email</th>
                        <th>Téléphone</th>
                        <th>Site internet</th>

                        {
                            /*
                             <th>Portable</th>
                             <th>Adresse</th>
                            */
                        }
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            {
                                this.props.getCustomerDetail.lastname
                                    ? this.props.getCustomerDetail.lastname
                                    : 'Aucun nom'
                            }
                        </td>
                        <td>
                            {
                                this.props.getCustomerDetail.firstname
                                    ? this.props.getCustomerDetail.firstname
                                    : 'Aucun prenom'
                            }
                        </td>
                        <td>
                            {this.props.getCustomerDetail.company_name ? this.props.getCustomerDetail.company_name : 'Aucun nom de société défini'}
                        </td>
                        <td>

                            {
                                this.props.getCustomerDetail.email
                                    ?
                                    <a href={"mailto:" + this.props.getCustomerDetail.email}>{this.props.getCustomerDetail.email}</a>
                                    : 'Aucun email'
                            }
                        </td>
                        <td>
                            {
                                this.props.getCustomerDetail.phone
                                    ? <a href={"tel:" + this.props.getCustomerDetail.phone}>{this.props.getCustomerDetail.phone}</a>
                                    : 'Aucun numéro de téléphone'
                            }
                        </td>
                        <td>
                            {
                                this.props.getCustomerDetail.website_url
                                    ? <a href={this.props.getCustomerDetail.website_url} target="_blank">{this.props.getCustomerDetail.website_url}</a>
                                    : 'Aucun site web enregistré'
                            }
                        </td>
                        {
                            /*
                             <td>
                            {
                                this.props.getCustomerDetail.portable
                                    ? <a href={"tel:" + this.props.getCustomerDetail.portable}>{this.props.getCustomerDetail.portable}</a>
                                    : 'Aucun numéro de portable'
                            }
                        </td>
                        <td>
                            {
                                this.props.getCustomerDetail.street ? this.props.getCustomerDetail.street + ' ' : null
                            }
                            {
                                this.props.getCustomerDetail.zip ? this.props.getCustomerDetail.zip + ' ' : null
                            }
                            {
                                this.props.getCustomerDetail.city ? this.props.getCustomerDetail.city + ' ' : null
                            }
                            {
                                this.props.getCustomerDetail.state ? this.props.getCustomerDetail.state + ' ' : null
                            }
                            {
                                !this.props.getCustomerDetail.street && !this.props.getCustomerDetail.zip && !this.props.getCustomerDetail.city && !this.props.getCustomerDetail.state ?
                                    'Aucune adresse'
                                    : null

                            }
                        </td>
                            */
                        }
                    </tr>
                    </tbody>
                </Table>
                <Table striped bordered responsive variant="dark">
                    <thead>
                    <tr>
                        {
                            /*
                            <th>Adresse de livraison</th>
                            <th>Siret</th>
                            <th>Client rattaché</th>
                            */
                        }
                        <th>Url iweb</th>
                        <th>Nom de la campagne</th>
                        <th>Mot de passe iweb</th>
                        <th>Ancien client</th>
                        <th>Responsable</th>
                        <th>Crée le :</th>
                        <th>Dernière modification le :</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                        {
                            /*
                            <td>
                            {
                                this.props.getCustomerDetail.street_delivery ? this.props.getCustomerDetail.street_delivery + ' ' : null
                            }
                            {
                                this.props.getCustomerDetail.zip_delivery ? this.props.getCustomerDetail.zip_delivery + ' ' : null
                            }
                            {
                                this.props.getCustomerDetail.city_delivery ? this.props.getCustomerDetail.city_delivery + ' ' : null
                            }
                            {
                                this.props.getCustomerDetail.state_delivery ? this.props.getCustomerDetail.state_delivery + ' ' : null
                            }
                            {
                                !this.props.getCustomerDetail.street_delivery && !this.props.getCustomerDetail.zip_delivery && !this.props.getCustomerDetail.city_delivery && !this.props.getCustomerDetail.state_delivery ?
                                    'Aucune adresse de livraison'
                                    : null
                            }
                            </td>
                            <td>
                            {this.props.getCustomerDetail.siret ? this.props.getCustomerDetail.siret : 'Non défini'}
                            </td>
                            <td>
                            {this.props.getCustomerDetail.customer_firstname ?
                                this.props.getCustomerDetail.customer_firstname : 'Non défini'}

                            {this.props.getCustomerDetail.customer_lastname ?
                                ' ' + this.props.getCustomerDetail.customer_lastname : null}
                            </td>
                            */
                        }
                        <td>
                            {
                                this.props.getCustomerDetail.website_url_iweb
                                    ? <a href={this.props.getCustomerDetail.website_url_iweb} target="_blank">{this.props.getCustomerDetail.website_url_iweb}</a>
                                    : 'Aucune url iweb enregistré'
                            }
                        </td>
                        <td>
                            {
                                this.props.getCustomerDetail.ads_campaign_name
                                    ? this.props.getCustomerDetail.ads_campaign_name
                                    : 'Aucun nom de campagne enregistré'
                            }
                        </td>
                        <td>
                            {
                                this.props.getCustomerDetail.password_iweb
                                    ? this.props.getCustomerDetail.password_iweb
                                    : 'Aucun mot de passe iweb enregistré'
                            }
                        </td>
                        <td>
                            {
                                !this.props.getCustomerDetail.older_customer
                                    ? 'Non'
                                    : 'Oui'
                            }
                        </td>
                        <td>
                            {this.props.getCustomerDetail.user_firstname ?
                                this.props.getCustomerDetail.user_firstname : 'Non défini'}

                            {this.props.getCustomerDetail.user_lastname ?
                                ' ' + this.props.getCustomerDetail.user_lastname : null}
                        </td>
                        <td>
                            <Moment local format="DD/MM/YYYY">{this.props.getCustomerDetail.register_date}</Moment>
                        </td>
                        <td>
                            <Moment local format="DD/MM/YYYY">{this.props.getCustomerDetail.last_modified_date}</Moment>
                        </td>
                    </tr>
                    </tbody>
                </Table>

                <div className="row mb-3 border-bottom">
                    <div className="col-4  pt-3 border-right">
                        <h6>Ajouter un commentaire sur le client</h6>
                        <CommentForm onSubmit={(data) => this.handleSubmit(data)}/>
                    </div>
                    <div className="col-8  pt-3 bg-white">
                        <CommentList/>
                    </div>
                </div>

                {
                    //Date.now().toLocaleDateString()
                }
                {
                    /*
                    <TaskCustomerTableComponent/>
                    <DocumentCustomerTableComponent/>
                     */
                }

            </div>
        )
    }
}


export default connect(mapStateToProps, null)(DetailCustomerComponent)