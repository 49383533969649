import axios from "../axios";
import {
    GET_ALL_DOCUMENTS_OF_CUSTOMER,
    GET_DOCUMENTS_DETAILS,
    ADD_DOCUMENT,
    DELETE_DOCUMENT,
    DOCUMENTS_LOADING,
    UPDATE_DOCUMENT,
    DELETE_DOCUMENT_DETAILS, GET_ALL_TASKS_OF_CUSTOMER, DELETE_CUSTOMER_DETAILS
} from "./types";
import {tokenConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {setTasksLoading} from "./taskActions";
import download from "downloadjs";

export const getAllDocumentsOfCustomer = (id) => dispatch => {
    dispatch(setDocumentsLoading());
    axios
        .get('/api/documents/getAllFiles/' + id)
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_DOCUMENTS_OF_CUSTOMER,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_DOCUMENTS_OF_CUSTOMER,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};
export const getDocumentsById = (id) => {
    return (dispatch) => {
        axios
            .get('/api/documents/' + id)
            .then(function (response){
                dispatch({
                    type: GET_DOCUMENTS_DETAILS,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    }
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_DOCUMENTS_DETAILS,
                    payload: {
                        data: false,
                        errorMessage: error?.message,
                    }
                })
            })
    }
}

export const addDocument = (data) => {
    return (dispatch, getState) => {
        axios
            .post('/api/documents', data, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: ADD_DOCUMENT,
                    payload: {
                        data: response?.data,
                        errorMessage: false
                    }
                })
            }).catch(function (error) {
            dispatch({
                type: ADD_DOCUMENT,
                payload: {
                    data: false,
                    errorMessage: error?.response?.data?.msg,
                }
            });
        })
    };
}

export const updateDocument = (document, id) => {
    return (dispatch, getState) => {
        axios
            .put("/api/documents/"+id, document, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: UPDATE_DOCUMENT,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    },
                });
            })
            .catch(function (error) {
                dispatch({
                    type: UPDATE_DOCUMENT,
                    payload: {
                        data: false,
                        errorMessage: error?.message
                    },
                });
            })
    };
}


export const deleteDataDocument = () => {
    return (dispatch) => {
        dispatch({
            type: GET_DOCUMENTS_DETAILS,
            payload: {
                data: false,
                errorMessage: false,
            }
        });

        dispatch({
            type: ADD_DOCUMENT,
            payload: {
                data: false,
                errorMessage: false,
            }
        });
    }
}

/*export const deleteDocument = id => (dispatch, getState) => {
    dispatch(setDocumentsLoading());
    axios
        .put(`/api/documents/delete/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_DOCUMENT_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err?.response?.data, err?.response?.status)))
};*/

export const deleteDocument = id => (dispatch, getState) => {
    axios
        .delete(`/api/documents/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_DOCUMENT_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};


export const setDocumentsLoading = () => {
    return {
        type: DOCUMENTS_LOADING,
    };
};
