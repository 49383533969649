import React, {Component} from "react";
import Comment from "./Comment";
import {connect} from 'react-redux';
import {Spinner} from "reactstrap";



const mapStateToProps = (state) => {
    return {
        getAllUsers: state.users.getAllUsers ? state.users.getAllUsers : JSON.parse(localStorage.getItem('allUsers')),
        getAllCustomers: state.customers.getAllCustomers ? state.customers.getAllCustomers : JSON.parse(localStorage.getItem('allCustomers')),
        getAllCommentsOfCustomer: state.comments.getAllCommentsOfCustomer,
        getResponDataComment: state.comments.getResponDataComment,
        errorResponDataComment: state.comments.errorResponDataComment,
        loading: state.comments.loading
    };
};

class CommentList extends Component {

    render() {

       const sortedComment =  this.props.getAllCommentsOfCustomer.sort((a,b) => new Date(b.date) - new Date(a.date));
        return (
            <div className="commentList">
                <h5 className="text-muted mb-4">
                    <span className="badge badge-success">{this.props.getAllCommentsOfCustomer.length}</span>{" "}
                    Commentaire{this.props.getAllCommentsOfCustomer.length > 1 ? "s" : ""}
                    <div className="text-center">
                        {
                            this.props.loading ? <Spinner color="dark"/> : null
                        }
                    </div>
                </h5>

                {this.props.getAllCommentsOfCustomer.length === 0 ? (
                    <div className="alert text-center alert-info">
                        Aucun commentaire sur le client
                    </div>
                ) : sortedComment.map((comments) => (
                            <Comment key={comments._id} comment={comments}/>
                        )
                    )}
            </div>
        );
    }
}

export default connect(mapStateToProps,null)(CommentList)