import React, {Component} from 'react';
import CustomerTableComponent from "../../components/Customers/CustomerTableComponent";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import {getAllOlderCustomers, deleteDataCustomer} from '../../actions/customerActions';
import {getAllUsers} from "../../actions/userActions";


 class OlderCustomerContainer extends Component {

     state = {
         msg: null
     }
     static propTypes = {
         getAllOlderCustomers: PropTypes.func.isRequired,
         isAuthenticated: PropTypes.bool,
     }

     componentDidMount() {
         this.props.getAllOlderCustomers();
         this.props.getAllUsers();
         this.props.deleteDataCustomer();
     }

     render() {
        return (
            <div>
                {this.props.isAuthenticated &&
                <div>
                    <CustomerTableComponent isOlderCustomer = {true}/>
                </div>
                }
                {!this.props.isAuthenticated &&
                <div>
                    <h2>Veuillez vous connecter pour accéder au CRM</h2>
                </div>
                }

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    getAllOlderCustomers: state.tasks.getAllOlderCustomers,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, {getAllOlderCustomers, getAllUsers, deleteDataCustomer})(OlderCustomerContainer)