import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Container, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faTasks, faEdit, faTrash, faUserPlus, faPlus} from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider, {CSVExport, Search} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import Moment from "react-moment";
import moment from "moment";
import {
    deleteBilling,
    getAllBillings,
    getAllBillingsOfCustomer,
    getAllBillingsOfToday,
    getAllBillingsOfTodayOfUser, getAllBillingsOfUser
} from "../../actions/billingActions";
import Swal from "sweetalert2";
import  { Link,Redirect } from 'react-router-dom';
import {getCustomerDetails} from "../../actions/customerActions";

moment.locale('fr');


const {SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const handleClick = (dispatch, id) => {

    Swal.fire({
        title: "Êtes vous sur de vouloir supprimer cette facture ?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        denyButtonText: `Annuler`,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete.isConfirmed) {
                dispatch(deleteBilling(id))
                Swal.fire({
                    title: "Facture supprimé avec succés",
                    text: "",
                    icon: "success"})
                    .then((result) => {
                    if(result.isConfirmed){
                        const user = JSON.parse(localStorage.getItem('user'));
                        dispatch(getAllBillings());
                        dispatch(getAllBillingsOfToday());
                        dispatch(getAllBillingsOfTodayOfUser(user.id));
                        dispatch(getAllBillingsOfUser(user.id))
                    }
                });
            } else {
                Swal.fire("La facture est sauvegardée", "","info");
            }
        });
}

const mapStateToProps = (state) => {
    return {
        user: JSON.parse(localStorage.getItem('user')),
        getAllBillings: state.billings.getAllBillings,
        getAllBillingsOfToday: state.billings.getAllBillingsOfToday,
        getAllBillingsOfTodayOfUser: state.billings.getAllBillingsOfTodayOfUser,
        getAllBillingsOfUser: state.billings.getAllBillingsOfUser,
        isAuthenticated: state.auth.isAuthenticated,
        errorBillingsList: state.billings.errorBillingsList,
        loading: state.billings.loading
    }
}

const BillingTableComponent = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));

    const handleClickCreate = (row) => {
        {
            props.dispatch(getCustomerDetails(row.customer_id))
        }
        return <Link to={"createBilling"}/>
    }

    if (!props.isAuthenticated) {
        return <Link to="/"/>
    }

    const defaultSorted = [{
            dataField: 'billing_status',
            order: 'asc'
        }]

    const optionsAllBillingsOfTodayOfUser = {
        paginationSize: 4,
        pageStartIndex: 0,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '30', value: 30
        }, {
            text: '25', value: 25
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };
    const optionsAllBillings = {
        paginationSize: 4,
        pageStartIndex: 0,
        sizePerPageList: [{
            text: '50', value: 50
        }, {
            text: '30', value: 30
        }, {
            text: '25', value: 25
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };
    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: '_id',
            text: 'ID',
            sort: true,
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: 'date',
            text: 'A encaisser le',
            sort: true,
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                const date = moment(rowContent).format('L');
                return (
                    date
                )
            }
        },
        {
            dataField: 'customer_id',
            text: 'Client Id',
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'customer_firstname',
            text: 'Client',
            sort: true,
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if (row.customer_firstname && !row.customer_lastname) {
                    return (
                        row.customer_firstname
                    )
                } else if (!row.customer_firstname && row.customer_lastname) {
                    return (
                        row.customer_lastname
                    )
                } else if (row.customer_firstname && row.customer_lastname) {
                    return (
                        row.customer_firstname + ' ' + row.customer_lastname
                    )
                }
            },
            filterValue: (rowContent, row) => {
                if (row.customer_firstname && !row.customer_lastname) {
                    return row.customer_firstname
                } else if (!row.customer_firstname && row.customer_lastname) {
                    return row.customer_lastname
                } else if (row.customer_firstname && row.customer_lastname) {
                    return row.customer_firstname + ' ' + row.customer_lastname
                }
            }
        },
        {
            dataField: 'customer_lastname',
            text: 'Client',
            sort: true,
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                    <Link to={"detailCustomer/" + row.customer_id}>
                        {
                            row.customer_firstname ? row.customer_firstname + ' ' : null
                        }

                        {
                            row.customer_lastname ? row.customer_lastname + ' ' : null
                        }
                    </Link>
                )
            },
            filterValue: (rowContent, row) => {
                if (row.customer_firstname && !row.customer_lastname) {
                    return row.customer_firstname
                } else if (!row.customer_firstname && row.customer_lastname) {
                    return row.customer_lastname
                } else if (row.customer_firstname && row.customer_lastname) {
                    return row.customer_firstname + ' ' + row.customer_lastname
                }
            },
        },
        {
            dataField: 'type',
            text: 'Type',
            sort: true,
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
        },
        {
            dataField: 'description',
            text: 'Description',
            sort: true,
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
        },
        {
            dataField: 'amount',
            text: 'Montant',
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            sort: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if (rowContent) {
                    return (
                        rowContent + ' €'
                    )
                } else {
                    return (0 + '€');
                }

            }
        },
        {
            dataField: 'billing_status',
            text: 'Status',
            events: {
                onClick: function(e, column, columnIndex, row, rowIndex){
                    console.log(e);
                    console.log(column);
                    console.log(columnIndex);
                    console.log(row);
                    console.log(rowIndex);
                    window.location = "detailCustomer/" + row.customer_id
                }
            },
            sort: true,
            style: function (rowContent, row) {
                if (rowContent == 'paye') {
                    return ({
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: 'lime'
                    })
                } else if (rowContent == 'en_attente') {
                    return ({
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: 'yellow'
                    })
                } else if (rowContent == 'annule') {
                    return ({
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: 'red'
                    })
                } else if (rowContent == 'en_veille') {
                    return ({
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        backgroundColor: 'pink'
                    })
                }

            },
            formatter: (rowContent, row) => {
                if (rowContent == 'paye') {
                    return (
                        "Payé"
                    )
                } else if (rowContent == 'en_attente') {
                    return (
                        "En attente"
                    )
                } else if (rowContent == 'annule') {
                    return (
                        "Annulé"
                    )
                } else if (rowContent == 'en_veille') {
                    return (
                        "En veille"
                    )
                }
            },
            csvFormatter: (rowContent, row) => {
                if (rowContent == 'paye') {
                    return (
                        "Payé"
                    )
                } else if (rowContent == 'en_attente') {
                    return (
                        "En attente"
                    )
                } else if (rowContent == 'annule') {
                    return (
                        "Annulé"
                    )
                } else if (rowContent == 'en_veille') {
                    return (
                        "En veille"
                    )
                }
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
        },
        {
            dataField: 'user_id',
            text: 'Commercial/Gestionnaire ID',
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'user_firstname',
            text: 'Commercial/Gestionnaire',
            sort: true,
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                    row.user_firstname + ' ' + row.user_lastname
                )
            }
        },
        {
            dataField: 'user_lastname',
            text: 'Nom du Commercial/Gestionnaire',
            sort: true,
            hidden: true,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (

                    row.user_firstname + ' ' + row.user_lastname

                )
            }
        },
        {
            dataField: "link",
            text: "Action",
            csvExport: false,
            style: {
                overflow: "hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "18%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={"createBilling"}>
                            <Button color="success" className="mr-1" onClick={() => handleClickCreate(row)}>
                                <FontAwesomeIcon icon={faPlus}/>
                            </Button>
                        </Link>
                        <Link to={"detailBilling/" + row._id}>
                            <Button color="info" className="mr-1">
                                <FontAwesomeIcon icon={faInfo}/>
                            </Button>
                        </Link>
                        <Link to={"editBilling/" + row._id}>
                            <Button color="warning" className="mr-1">
                                <FontAwesomeIcon icon={faEdit}/>
                            </Button>
                        </Link>
                        <Button color="danger" className="mr-1" onClick={() => handleClick(props.dispatch, row._id)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                    </div>
                )
            }
        }
    ];

    /*
    const rowEvents = {
        onClick: function(e, row, rowIndex){
            window.location = "detailCustomer/" + row.customer_id
        }
    }
     */

    return (
        <Container>
            <div>
                <h2>
                    Liste des factures impayées à aujourd'hui ({props.getAllBillingsOfTodayOfUser.length})
                </h2>
            </div>
            {props.getAllBillingsOfTodayOfUser ?
                <ToolkitProvider
                    bootstrap4
                    keyField='_id'
                    data={props.getAllBillingsOfTodayOfUser}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    search={{
                        searchFormatted: true,
                        defaultSearch: localStorage.getItem('searchTextBillingDay')
                    }}
                    exportCSV={{
                        fileName: 'Liste_des_factures_du_jour_de' + props.getAllBillingsOfUser.user_firstname + '_' + props.getAllBillingsOfUser.user_lastname + '.csv',
                        onlyExportFiltered: true,
                        exportAll: false,
                        separator: ";"
                    }}
                >
                    {(props) => (
                        <div>
                            <Row>
                                <Col>
                                    <Link to={"createBilling"}>
                                        <Button color="primary" className="mr-2">
                                            <FontAwesomeIcon icon={faTasks}/> Crée une facture
                                        </Button>
                                    </Link>
                                    {
                                        /*
                                        <ExportCSVButton {...props.csvProps} className="mr-2">
                                            <Button color="info">
                                                Export au format CSV
                                            </Button>
                                        </ExportCSVButton>
                                         */
                                    }
                                </Col>
                                <Col>
                                    <div className="float-right">
                                        <SearchBar {...props.searchProps} placeholder="Search.."/>
                                        {localStorage.setItem('searchTextBillingDay', props.searchProps.searchText)}
                                    </div>
                                </Col>
                            </Row>
                            <BootstrapTable striped
                                            hover
                                            defaultSorted={defaultSorted}
                                            //rowEvents={rowEvents}
                                            {...props.baseProps}
                                            pagination={paginationFactory(optionsAllBillingsOfTodayOfUser)}/>
                        </div>
                    )}
                </ToolkitProvider> :
                (
                    <div className="text-center">
                        {
                            props.errorBillingsList ? <h4>{props.errorBillingsList}</h4> :
                                <Spinner color="dark"/>
                        }
                    </div>
                )}

            {
                /*
            <div>
                <h2>
                    Liste de toutes vos factures ({props.getAllBillingsOfUser.length})
                </h2>
            </div>
                 */
            }
            {
                /*
                props.getAllBillingsOfUser ?

                <ToolkitProvider
                    bootstrap4
                    keyField='_id'
                    data={props.getAllBillingsOfUser}
                    columns={columns}
                    defaultSorted={defaultSorted}
                    search={ {
                        searchFormatted: true
                    } }
                    exportCSV={ {
                        fileName: 'Liste des factures de '+props.getAllBillingsOfUser.user_firstname + '_'+props.getAllBillingsOfUser.user_lastname+'.csv',
                        onlyExportFiltered: true,
                        exportAll: false,
                        separator: ";"
                    }}
                >
                    {(props) => (
                        <div>
                            <Row>
                                {
                                    <Col>
                                        <ExportCSVButton {...props.csvProps} className="mr-2">
                                            <Button color="info">
                                                Export au format CSV
                                            </Button>
                                        </ExportCSVButton>
                                    </Col>
                                }
                                <Col>
                                    <div className="float-right">
                                        <SearchBar {...props.searchProps} placeholder="Search.."/>
                                    </div>
                                </Col>
                            </Row>
                            <BootstrapTable striped
                                            hover
                                            {...props.baseProps} pagination={paginationFactory()}/>
                        </div>
                    )}
                </ToolkitProvider> :
                (
                    <div className="text-center">
                        {
                            props.errorBillingsList ? <h4>{props.errorBillingsList}</h4> :
                                <Spinner color="dark"/>
                        }
                    </div>
                )}
                 */
            }

            {user.role == 'admin' ?
                <div>
                    <div>
                        <h2>
                            Liste de toutes les factures ({props.getAllBillings.length})
                        </h2>
                    </div>
                    {
                        props.getAllBillings ?
                            <ToolkitProvider
                                bootstrap4
                                keyField='_id'
                                data={props.getAllBillings}
                                columns={columns}
                                defaultSorted={defaultSorted}
                                search={{
                                    searchFormatted: true,
                                    defaultSearch: localStorage.getItem('searchTextBilling')
                                }}
                                exportCSV={{
                                    fileName: 'Liste_des_toutes_les_factures.csv',
                                    onlyExportFiltered: true,
                                    exportAll: false,
                                    separator: ";"
                                }}

                            >
                                {(props) => (
                                    <div>
                                        <Row>
                                            {
                                                /*
                                                <Col>
                                                    <ExportCSVButton {...props.csvProps} className="mr-2">
                                                        <Button color="info">
                                                            Export au format CSV
                                                        </Button>
                                                    </ExportCSVButton>
                                                </Col>
                                                 */
                                            }
                                            <Col>
                                                <div className="float-right">
                                                    <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                                    {localStorage.setItem('searchTextBilling', props.searchProps.searchText)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <BootstrapTable striped
                                                        hover
                                                        defaultSorted={defaultSorted}
                                                        {...props.baseProps}
                                                        //rowEvents={rowEvents}
                                                        pagination={paginationFactory(optionsAllBillings)}/>
                                    </div>
                                )}
                            </ToolkitProvider> :
                            (
                                <div className="text-center">
                                    {
                                        props.errorBillingsList ? <h4>{props.errorBillingsList}</h4> :
                                            <Spinner color="dark"/>
                                    }
                                    {
                                        props.loading ? <Spinner color="dark"/> : null
                                    }
                                </div>
                            )}
                    <div className="text-center">
                        {
                            props.loading ? <Spinner color="dark"/> : null
                        }
                    </div>
                </div> : null
            }
        </Container>

    )
}


export default connect(mapStateToProps, null)(BillingTableComponent);