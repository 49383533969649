import {
    GET_USER_DETAILS,
    ADD_USER,
    UPDATE_USER,
    DELETE_USER,
    USERS_LOADING,
    GET_ALL_USERS
} from "../actions/types";

const initialState = {
    users: [],
    getAllUsers: false,
    getUserDetail: false,
    getResponDataUser: false,
    errorResponDataUser: false,
    errorUserDetail: false,
    loading: false,
    errorUsersList: false
}

const users = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USERS:
            localStorage.setItem('allUsers', JSON.stringify(action.payload.data));
            return {
                ...state,
                getAllUsers: action.payload.data,
                loading: false
            };
        case GET_USER_DETAILS:
            return {
                ...state,
                getUserDetail: action.payload.data,
                errorUserDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user._id !== action.payload),
                loading: false
            };
        case ADD_USER:
            return {
                ...state,
                getResponDataUser: action.payload.data,
                errorResponDataUser: action.payload.errorMessage,
                loading: false
            };
        case UPDATE_USER:
            return {
                ...state,
                getResponDataUser: action.payload.data,
                errorResponDataUser: action.payload.errorMessage,
                loading: false
            }
        case USERS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default users