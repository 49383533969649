import React, {Component, Fragment} from 'react';
import {NavLink} from 'reactstrap';
import {connect} from 'react-redux';
import {logout} from '../../actions/authActions';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";

export class Logout extends Component {

    static propTypes = {
        logout: PropTypes.func.isRequired
    };

    render() {
        return (
            <Fragment>
                    <NavLink onClick={this.props.logout} href='#'>
                        Se déconnecter
                    </NavLink>
            </Fragment>
        );
    }
}

export default connect(null, {logout})(Logout);