import React from "react";
import moment from "moment"

export default function Comment(props) {
    const { message, date, user_firstname, user_lastname } = props.comment;

    return (
        <div className="media mb-3">
            <div className="media-body p-2 shadow-sm rounded bg-light border">
                <small className="float-right text-muted">{moment(date).fromNow()}</small>
                <h6 className="mt-0 mb-1 text-muted">{user_firstname + ' ' + user_lastname}</h6>
                {message}
            </div>
        </div>
    );
}