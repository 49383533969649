const UserValidation = (values) => {
    const errors = {};

   /* if(!values.firstname || values.firstname === ""){
        errors.firstname = "Le prenom est obligatoire"
    }
    if(!values.lastname || values.lastname === ""){
        errors.lastname = "La nom est obligatoire"
    }
    if(!values.email || values.email === ""){
        errors.email = "L'email est obligatoire"
    }
    if(values.role == null){
        errors.role = "Définir si il s'agit d'un admin ou un commercial est obligatoire"
    }*/
    if((!values.firstname || values.firstname === "") &&
        (!values.lastname || values.lastname === "") &&
        (!values.email || values.email === "" )){
        errors.lastname = "Remplissez au moins le prenom, le nom ou l'email svp"
    }
    /*if(!values.password || values.password === ""){
        errors.password = "Le mot de passe est obligatoire"
    }*/
    return errors
};

export default UserValidation;