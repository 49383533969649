import {
    GET_ALL_BILLINGS,
    GET_BILLING_DETAILS,
    ADD_BILLING,
    UPDATE_BILLING,
    DELETE_BILLING,
    BILLINGS_LOADING,
    GET_ALL_BILLINGS_OF_TODAY,
    GET_ALL_BILLINGS_OF_CUSTOMER,
    GET_ALL_BILLINGS_OF_USER, GET_ALL_BILLINGS_OF_TODAY_OF_USER
} from "../actions/types";
import moment from "moment";

const initialState = {
    billings: [],
    getAllBillings: false,
    getAllBillingsOfToday: false,
    getAllBillingsOfTodayOfUser: false,
    getAllBillingsOfCustomer: false,
    getAllBillingsOfUser: false,
    getBillingDetail: false,
    getCustomerDetail: false,
    getResponDataBilling: false,
    errorResponDataBilling: false,
    errorBillingDetail: false,
    loading: false,
    errorBillingsList: false
}

const billings = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_BILLINGS:
            return {
                ...state,
                getAllBillings: action.payload.data,
                loading: false
            };
        case GET_ALL_BILLINGS_OF_TODAY:
            return {
                ...state,
                getAllBillingsOfToday: action.payload.data,
                loading: false
            };
            case GET_ALL_BILLINGS_OF_TODAY_OF_USER:
            return {
                ...state,
                getAllBillingsOfTodayOfUser: action.payload.data,
                loading: false
            };
        case GET_ALL_BILLINGS_OF_CUSTOMER:
            return {
                ...state,
                getAllBillingsOfCustomer: action.payload.data,
                loading: false
            };
        case GET_ALL_BILLINGS_OF_USER:
            return {
                ...state,
                getAllBillingsOfUser: action.payload.data,
                loading: false
            };
        case GET_BILLING_DETAILS:
            return {
                ...state,
                getBillingDetail: action.payload.data,
                errorBillingDetail: action.payload.errorMessage,
                loading: false
            };
        case DELETE_BILLING:
            return {
                ...state,
                billings: state.billings.filter(billing => billing._id !== action.payload),
                loading: false
            };
        case ADD_BILLING:
            return {
                ...state,
                getResponDataBilling: action.payload.data,
                errorResponDataBilling: action.payload.errorMessage,
                loading: false
            };
        case UPDATE_BILLING:
            return {
                ...state,
                getResponDataBilling: action.payload.data,
                errorResponDataBilling: action.payload.errorMessage
            }
        case BILLINGS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export default billings