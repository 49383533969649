import axios from "../axios";
import {returnErrors} from "./errorActions";
import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_FAIL,
    REGISTER_SUCCESS
} from "../actions/types";
import {getAllCustomers, getAllProspects} from "./customerActions";


// Check token & load user
export const loadUser = () => (dispatch, getState) => {
    // User loading
    dispatch({type: USER_LOADING});

    // Get token from localstorage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers
    if (token) {
        config.headers['Authorization'] = token;
    }
    axios.get('/api/auth/user', tokenConfig(getState))
        .then(res => dispatch({
            type: USER_LOADED,
            payload: res?.data
        }))
        .catch(err => {
            dispatch(returnErrors(err?.response?.data, err?.response?.status));
            dispatch({type: AUTH_ERROR})
        });
};

// Register User

export const register = ({
                             lastname,
                             firstname,
                             email,
                             password,
                             phone,
                             street,
                             city,
                             state,
                             zip,
                             role
                         }) => dispatch => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Request body
    const body = JSON.stringify({
        lastname,
        firstname,
        email,
        password,
        phone,
        street,
        city,
        state,
        zip,
        role
    })
    axios.post('/api/users', body, config)
        .then(res => dispatch({
            type: REGISTER_SUCCESS,
            payload: res?.data
        }))
        .catch(err => {
            dispatch(returnErrors(err?.response?.data, err?.response?.status, 'REGISTER_FAIL'));
            dispatch({
                type: REGISTER_FAIL
            });
        });
}

// Login User
export const login = ({

                          email,
                          password
                      }) => dispatch => {
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    // Request body
    const body = JSON.stringify({
        email,
        password
    })
    axios.post('/api/auth', body, config)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res?.data
            });
            dispatch(getAllCustomers());
            dispatch(getAllProspects());
        })
        .catch(err => {
            dispatch(returnErrors(err?.response?.data, err?.response?.status, 'LOGIN_FAIL'));
            dispatch({
                type: LOGIN_FAIL
            });
        });
}


// Logout User
export const logout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};

// Setup config/headers and token
export const tokenConfig = getState => {
    // Get token from localstorage
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers
    if (token) {
        config.headers['Authorization'] = token;
    }

    return config;
}