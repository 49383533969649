import axios from "../axios";
import {
    GET_COMMENTS_DETAILS,
    ADD_COMMENT,
    DELETE_COMMENT,
    COMMENTS_LOADING,
    UPDATE_COMMENT,
    DELETE_COMMENT_DETAILS, GET_ALL_COMMENTS_OF_CUSTOMER
} from "./types";
import {tokenConfig} from "./authActions";
import {returnErrors} from "./errorActions";

export const getAllCommentsOfCustomer = (id) => dispatch => {
    dispatch(setCommentsLoading());
    axios
        .get('/api/comments/customer/' + id)
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_COMMENTS_OF_CUSTOMER,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_COMMENTS_OF_CUSTOMER,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};
export const getCommentsById = (id) => {
    return (dispatch) => {
        axios
            .get('/api/comments/' + id)
            .then(function (response){
                dispatch({
                    type: GET_COMMENTS_DETAILS,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    }
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_COMMENTS_DETAILS,
                    payload: {
                        data: false,
                        errorMessage: error?.message,
                    }
                })
            })
    }
}

export const addComment = (data) => {
    return (dispatch, getState) => {
        dispatch(setCommentsLoading());
        return new Promise((resolve, reject) => {
            axios
                .post('/api/comments', data, tokenConfig(getState))
                .then(function (response) {
                    dispatch({
                        type: ADD_COMMENT,
                        payload: {
                            data: response?.data,
                            errorMessage: false
                        }
                    })
                }).catch(function (error) {
                dispatch({
                    type: ADD_COMMENT,
                    payload: {
                        data: false,
                        errorMessage: error?.response?.data?.msg,
                    }
                });
            })
            resolve();
        });
    };
}

export const updateComment = (comment, id) => {
    return (dispatch, getState) => {
        axios
            .put("/api/comments/"+id, comment, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: UPDATE_COMMENT,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    },
                });
            })
            .catch(function (error) {
                dispatch({
                    type: UPDATE_COMMENT,
                    payload: {
                        data: false,
                        errorMessage: error?.message
                    },
                });
            })
    };
}


export const deleteDataComment = () => {
    return (dispatch) => {
        dispatch({
            type: GET_COMMENTS_DETAILS,
            payload: {
                data: false,
                errorMessage: false,
            }
        });

        dispatch({
            type: ADD_COMMENT,
            payload: {
                data: false,
                errorMessage: false,
            }
        });
    }
}

export const deleteComment = id => (dispatch, getState) => {
    axios
        .delete(`/api/comments/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_COMMENT_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err?.response?.data, err?.response?.status)))
};


export const setCommentsLoading = () => {
    return {
        type: COMMENTS_LOADING,
    };
};
