import axios from "../axios";
import {
    GET_ALL_CUSTOMERS,
    GET_CUSTOMER_DETAILS,
    ADD_CUSTOMER,
    DELETE_CUSTOMER,
    CUSTOMERS_LOADING,
    GET_TASK_DETAILS,
    ADD_TASK,
    UPDATE_TASK,
    UPDATE_CUSTOMER,
    DELETE_TASK_DETAILS,
    DELETE_CUSTOMER_DETAILS,
    GET_ALL_PROSPECTS_FOLLOWED,
    GET_ALL_PROSPECTS_MAIL_FOLLOWED,
    GET_ALL_PROSPECTS_RS_FOLLOWED,
    GET_ALL_PROSPECTS_MAIL,
    GET_ALL_PROSPECTS_RS, GET_ALL_OLDER_CUSTOMERS
} from "./types";
import {tokenConfig} from "./authActions";
import {returnErrors} from "./errorActions";

/**
 * Get all customers
 * @returns {(function(*, *): void)|*}
 */
export const getAllCustomers = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));

        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers' : user.role === 'gestionnaire_client' ? '/api/customers/customer_manager_access' : '/api/customers/user/' + user?.id)
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_CUSTOMERS,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_CUSTOMERS,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all old customers
 * @returns {(function(*, *): void)|*}
 */
export const getAllOlderCustomers = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/olderCustomer' : user.role === 'gestionnaire_client' ? '/api/customers/olderCustomer/customer_manager_access' : '/api/customers/olderCustomer/user/' + user?.id)
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_OLDER_CUSTOMERS,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_OLDER_CUSTOMERS,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all prospects
 * @returns {(function(*, *): void)|*}
 */
export const getAllProspects = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/prospects' : '/api/customers/prospects/user/' + user?.id +'/prospectType/Aucun')
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_CUSTOMERS,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_CUSTOMERS,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all prospects Followed
 * @returns {(function(*, *): void)|*}
 */
export const getAllProspectsFollowed = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/prospects/followed' : '/api/customers/prospects/followed/user/' + user?.id+'/prospectType/Aucun')
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_PROSPECTS_FOLLOWED,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_PROSPECTS_FOLLOWED,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all prospects Mail
 * @returns {(function(*, *): void)|*}
 */
export const getAllProspectsMail = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/prospects/prospectType/MAIL' : '/api/customers/prospects/user/prospectType/' + user?.id+'/prospectType/MAIL')
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_PROSPECTS_MAIL,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_PROSPECTS_MAIL,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all prospects Mail Followed
 * @returns {(function(*, *): void)|*}
 */
export const getAllProspectsMailFollowed = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/prospects/followed/prospectType/MAIL' : '/api/customers/prospects/followed/user/' + user?.id+'/prospectType/MAIL')
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_PROSPECTS_MAIL_FOLLOWED,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_PROSPECTS_MAIL_FOLLOWED,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all prospects RS
 * @returns {(function(*, *): void)|*}
 */
export const getAllProspectsRS = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/prospects/prospectType/RS' : '/api/customers/prospects/user/' + user?.id+'/prospectType/RS')
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_PROSPECTS_RS,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_PROSPECTS_RS,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get all prospects RS Followed
 * @returns {(function(*, *): void)|*}
 */
export const getAllProspectsRSFollowed = () => {
    return (dispatch, getState) => {
        const user = getState().auth.user || JSON.parse(localStorage.getItem('user'));
        dispatch(setCustomersLoading());
        axios
            .get(user?.role === 'admin' ? '/api/customers/prospects/followed/prospectType/RS' : '/api/customers/prospects/followed/user/' + user?.id+'/prospectType/RS')
            .then(res => {
                let data = res.data;
                for (let i = 0; i < res.data.length; i++) {
                    data[i] = {
                        ...data[i],
                        id: i + 1,
                    }
                }
                dispatch({
                    type: GET_ALL_PROSPECTS_RS_FOLLOWED,
                    payload: {
                        data: data,
                        errorMessage: false
                    }
                })
            })
            .catch(err => {
                dispatch({
                    type: GET_ALL_PROSPECTS_RS_FOLLOWED,
                    payload: {
                        data: false,
                        errorMessage: err?.message
                    }
                })
            })
    }
};

/**
 * Get Customer Details
 * @param id
 * @returns {(function(*): void)|*}
 */
export const getCustomerDetails = (id) => {
    return (dispatch) => {
        axios
            .get('/api/customers/' + id)
            .then(function (response){
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    }
                })
            })
            .catch(function (error) {
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: {
                        data: false,
                        errorMessage: error?.message,
                    }
                })
            })
    }
}

/**
 * Add new Customer
 * @param data
 * @returns {(function(*, *=): void)|*}
 */
export const addCustomer = (data) => {
    return (dispatch, getState) => {
        axios
            .post('/api/customers', data, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: ADD_CUSTOMER,
                    payload: {
                        data: response?.data,
                        errorMessage: false
                    }
                })
            }).catch(function (error) {
            dispatch({
                type: ADD_CUSTOMER,
                payload: {
                    data: false,
                    errorMessage: error?.response?.data?.msg,
                }
            });
        })
    };
}

/**
 * Update a Customer
 * @param customer
 * @param id
 * @returns {(function(*, *=): void)|*}
 */
export const updateCustomer = (customer, id) => {
    return (dispatch, getState) => {
        axios
            .put("/api/customers/"+id, customer, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    },
                });
            })
            .catch(function (error) {
                dispatch({
                    type: UPDATE_CUSTOMER,
                    payload: {
                        data: false,
                        errorMessage: error?.message
                    },
                });
            })
    };
}

/**
 * Delete data of Customer
 * @returns {(function(*): void)|*}
 */
export const deleteDataCustomer = () => {
    return (dispatch) => {
        dispatch({
            type: GET_CUSTOMER_DETAILS,
            payload: {
                data: false,
                errorMessage: false,
            }
        });

        dispatch({
            type: ADD_CUSTOMER,
            payload: {
                data: false,
                errorMessage: false,
            }
        });
    }
}

/**
 * Delete Customer
 * @param id
 * @returns {(function(*, *=): void)|*}
 */
export const deleteCustomer = id => (dispatch, getState) => {
    dispatch(setCustomersLoading());
    axios
        .put(`/api/customers/delete/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_CUSTOMER_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err?.response?.data, err.response?.status)))
};

/*export const deleteCustomer = id => (dispatch, getState) => {
    axios
        .delete(`/api/customers/${id}`, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: DELETE_CUSTOMER_DETAILS,
                payload: id
            })
        ).catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};*/

/**
 * Set Customers Loading
 * @returns {{type: string}}
 */
export const setCustomersLoading = () => {
    return {
        type: CUSTOMERS_LOADING,
    };
};
