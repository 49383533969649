import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {Button, Container, Spinner} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo,faTrash, faUserPlus, faUserEdit} from "@fortawesome/free-solid-svg-icons";
import ToolkitProvider, {Search, CSVExport} from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {Col, Row} from "react-bootstrap";
import {connect} from 'react-redux';
import Moment from "react-moment";
import moment from "moment";
import {
    deleteCustomer,
    getAllCustomers, getAllOlderCustomers, getAllProspects,
    getAllProspectsFollowed, getAllProspectsMail, getAllProspectsMailFollowed,
    getAllProspectsRS, getAllProspectsRSFollowed
} from "../../actions/customerActions";
import Swal from "sweetalert2";
import  { Link, Redirect } from 'react-router-dom';
import {isMobile} from 'react-device-detect'
if (isMobile) { require('../customer.scss'); }

moment.locale('fr');


const {SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const handleClick = (dispatch, id, isProspectsMail,isProspectsRS,isProspects ) => {

    Swal.fire({
        title: "Êtes vous sur de vouloir supprimer ce prospect ?",
        icon: "warning",
        buttons: true,
        showCancelButton: true,
        confirmButtonText: `Ok`,
        denyButtonText: `Annuler`,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete.isConfirmed) {
                dispatch(deleteCustomer(id))
                Swal.fire({
                    title: "Prospect supprimé avec succés",
                    text: "",
                    icon: "success"})
                    .then((result) => {
                        if(result.isConfirmed){
                            if(isProspects){
                                dispatch(getAllProspects());
                                dispatch(getAllProspectsFollowed());
                            }
                            if(isProspectsMail){
                                dispatch(getAllProspectsMail());
                                dispatch(getAllProspectsMailFollowed());

                            }
                            if(isProspectsRS){
                                dispatch(getAllProspectsRS());
                                dispatch(getAllProspectsRSFollowed());
                            }
                        }
                    });
            } else {
                Swal.fire("Le prospect est sauvegardé", "","info");
            }
        });
}



const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
}]

const mapStateToProps = (state) => {
    return {
        getAllProspects: state.customers.getAllCustomers,
        getAllProspectsFollowed: state.customers.getAllProspectsFollowed,
        getAllProspectsMail: state.customers.getAllProspectsMail,
        getAllProspectsMailFollowed: state.customers.getAllProspectsMailFollowed,
        getAllProspectsRS: state.customers.getAllProspectsRS,
        getAllProspectsRSFollowed: state.customers.getAllProspectsRSFollowed,
        isAuthenticated: state.auth.isAuthenticated,
        errorCustomersList: state.customers.errorCustomersList,
        errorTasksList: state.tasks.errorTasksList,
        loading: state.customers.loading
    }
}

const ProspectTableComponent = (props) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if(!props.isAuthenticated){
        <Link to="/"/>
    }

    let getAllProspects = [];
    let getAllProspectsFollowed = [];
    const isProspects = props.isProspects;
    const isProspectsMail = props.isProspectsMail;
    const isProspectsRS = props.isProspectsRS;
    if(isProspects){
        getAllProspects = props.getAllProspects;
        getAllProspectsFollowed = props.getAllProspectsFollowed;

    }
    if(isProspectsMail){
        getAllProspects = props.getAllProspectsMail;
        getAllProspectsFollowed = props.getAllProspectsMailFollowed;

    }
    if(isProspectsRS){
        getAllProspects = props.getAllProspectsRS;
        getAllProspectsFollowed = props.getAllProspectsRSFollowed
    }


    const options = {
        paginationSize: 4,
        pageStartIndex: 0,
        sizePerPageList: [{
            text: '50', value: 50
        },{
            text: '30', value: 30
        },{
            text: '25', value: 25
        }, {
            text: '10', value: 10
        }] // A numeric array is also available. the purpose of above example is custom the text
    };

    const columns = [
        {
            dataField: 'id',
            text: 'ID',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "7%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: '_id',
            text: 'ID',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "20%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            },
        },
        {
            dataField: 'lastname',
            text: 'Nom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'firstname',
            text: 'Prenom',
            sort: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'email',
            text: 'Email',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"mailto:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'phone',
            text: 'Téléphone',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"tel:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'portable',
            text: 'Portable',
            sort: true,
            hidden: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                            <a href={"tel:" + rowContent}>{rowContent}</a>
                )
            }
        },
        {
            dataField: 'website_url',
            text: 'Site internet',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                    <a href={"https://" + rowContent} target="_blank">{rowContent}</a>
                )
            }
        },
        {
            dataField: 'website_url_iweb',
            text: 'Url iweb',
            sort: true,
            hidden: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
            formatter: (rowContent, row) => {
                return (
                    <a href={rowContent} target="_blank">{rowContent}</a>
                )
            }
        },
        {
            dataField: 'ads_campaign_name',
            text: 'Nom de la campagne',
            sort: true,
            hidden: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'password_iweb',
            text: 'Mot de passe iweb',
            sort: true,
            hidden: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'company_name',
            text: 'Nom de société',
            sort: true,
            maxLength: 10,
            style: {
                whiteSpace: "nowrap",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "13%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'register_date',
            text: 'Enregistré le',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                const date = moment(rowContent).format('L');
                return (
                    date
                )
            }
        },
        {
            dataField: 'membership',
            text: 'Client/Prospect',
            sort: true,
            hidden: true,
            style: {
                width: "10%",
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                if(rowContent){
                    return (
                        "Client"
                    )
                }else{
                    return (
                        "Prospect"
                    )
                }
            },
            csvFormatter: (rowContent, row) => {
                if(rowContent){
                    return (
                        "Client"
                    )
                }else{
                    return (
                        "Prospect"
                    )
                }
            },
            headerStyle: () => {
                return {
                    width: "10%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"};
            },
        },
        {
            dataField: 'user_id',
            text: 'Id du responsable',
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
        },
        {
            dataField: 'user_firstname',
            text: 'Prenom du responsable',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                            row.user_firstname + ' ' + row.user_lastname
                )
            }
        },
        {
            dataField: 'user_lastname',
            text: 'Nom de famille du responsable',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            formatter: (rowContent, row) => {
                return (
                    row.user_firstname + ' ' + row.user_lastname
                )
            }
        },
        {
            dataField: 'street',
            text: 'Rue',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'zip',
            text: 'Code postal',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'city',
            text: 'Ville',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'state',
            text: 'Pays',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'street_delivery',
            text: 'Rue de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'zip_delivery',
            text: 'Code postal de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'city_delivery',
            text: 'Ville de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'state_delivery',
            text: 'Pays de livraison',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: 'siret',
            text: 'Siret',
            sort: true,
            hidden: true,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            }
        },
        {
            dataField: "link",
            text: "Action",
            csvExport: false,
            style: {
                overflow:"hidden",
                textOverflow: "ellipsis"
            },
            headerStyle: () => {
                return {
                    width: "16%",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                };
            },
            formatter: (rowContent, row) => {
                return (
                    <div>
                        <Link to={isProspectsMail ? "detailProspectMail/" + row._id : isProspectsRS ? "detailProspectRS/" + row._id : "detailProspect/" + row._id}>
                            <Button color="info" className="mr-2">
                                <FontAwesomeIcon icon={faInfo}/>
                            </Button>
                        </Link>
                            <Link to={ isProspectsMail ? "editProspectMail/" + row._id : isProspectsRS ? "editProspectRS/" + row._id : "editProspect/" + row._id}>
                                <Button color="warning" className="mr-2">
                                    <FontAwesomeIcon icon={faUserEdit}/>
                                </Button>
                            </Link>
                        {user?.role === 'admin' &&
                        <Button color="danger" className="mr-2" onClick={() => handleClick(props.dispatch,row._id, isProspectsMail, isProspectsRS, isProspects)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </Button>
                        }
                    </div>
                )
            }
        }
    ];

    return (
    <Container>
        <div>
            <h2>
                { isProspectsMail ? 'Liste des prospects Mail à suivre' : isProspectsRS ? 'Liste des prospects RS à suivre' : 'Liste des prospects à suivre'}
                {/*Liste des prospects à suivre ({props.getAllProspects.length})*/}
            </h2>
        </div>
        {getAllProspectsFollowed ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={getAllProspectsFollowed}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextProspectFollowed')
                }}
                exportCSV={ {
                    fileName: 'ProspectsSuivi.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                    separator: ";"
                }}
            >
                {(props) => (
                    <div>
                        <Row>
                            <Col>
                                <Link to={isProspectsMail ? "createProspectMail" : isProspectsRS ? "createProspectRS" : "createProspect" }>
                                    <Button color="primary" className="mr-2">
                                        <FontAwesomeIcon icon={faUserPlus}/> {isProspectsMail ? "Ajouter un prospect Mail" : isProspectsRS ? "Ajouter un prospect RS" : "Ajouter un prospect" }
                                    </Button>
                                </Link>
                                {
                                    /*
                                    user?.role === 'admin' &&
                                <ExportCSVButton {...props.csvProps} className="mr-2">
                                    <Button color="info">
                                        Export au format CSV
                                    </Button>
                                </ExportCSVButton>
                                     */
                                }
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                    {localStorage.setItem('searchTextProspectFollowed', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable id="table-to-xls"
                                        striped
                                        hover
                                        printable
                                        wrapperClasses="table-responsive"
                                        bodyStyle={{overflow: 'overlay'}}
                                        {...props.baseProps} pagination={paginationFactory(options)}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorCustomersList ? <h4>{props.errorCustomersList}</h4> :
                            <Spinner color="dark"/>
                    }
                    {
                        props.loading ? <Spinner color="dark"/> : null
                    }
                </div>
            )}


        <div>
            <h2>
                { isProspectsMail ? 'Liste des prospects Mail' : isProspectsRS ? 'Liste des prospects RS' : 'Liste des prospects'}
                {/*Liste des prospects ({props.getAllProspects.length})*/}
            </h2>
        </div>
        {getAllProspects ?
            <ToolkitProvider
                bootstrap4
                keyField='_id'
                data={getAllProspects}
                columns={columns}
                defaultSorted={defaultSorted}
                search={ {
                    searchFormatted: true,
                    defaultSearch: localStorage.getItem('searchTextProspect')
                }}
                exportCSV={ {
                    fileName: 'Clients.csv',
                    onlyExportFiltered: true,
                    exportAll: false,
                    separator: ";"
                }}
            >
                {(props) => (
                    <div>
                        <Row>
                            {
                                /*
                                    <Col>
                                        <Link to={"createProspect"}>
                                            <Button color="primary" className="mr-2">
                                                <FontAwesomeIcon icon={faUserPlus}/> Ajouter un prospect
                                            </Button>
                                        </Link>

                                            user?.role === 'admin' &&
                                        <ExportCSVButton {...props.csvProps} className="mr-2">
                                            <Button color="info">
                                                Export au format CSV
                                            </Button>
                                        </ExportCSVButton>

                                    </Col>
                                */
                            }
                            <Col>
                                <div className="float-right">
                                    <SearchBar {...props.searchProps} placeholder="Rechercher.."/>
                                    {localStorage.setItem('searchTextProspect', props.searchProps.searchText)}
                                </div>
                            </Col>
                        </Row>
                        <BootstrapTable id="table-to-xls"
                                        striped
                                        hover
                                        printable
                                        wrapperClasses="table-responsive"
                                        bodyStyle={{overflow: 'overlay'}}
                                        {...props.baseProps} pagination={paginationFactory(options)}/>
                    </div>
                )}
            </ToolkitProvider> :
            (
                <div className="text-center">
                    {
                        props.errorCustomersList ? <h4>{props.errorCustomersList}</h4> :
                        <Spinner color="dark"/>
                    }
                    {
                        props.loading ? <Spinner color="dark"/> : null
                    }
                </div>
            )}
        <div className="text-center">
            {
                props.loading ? <Spinner color="dark"/> : null
            }
        </div>
    </Container>

)
};


export default connect(mapStateToProps, null)(ProspectTableComponent);