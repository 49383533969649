import axios from "../axios";
import {
    GET_ALL_BILLINGS,
    GET_ALL_BILLINGS_OF_TODAY,
    GET_ALL_BILLINGS_OF_USER,
    GET_ALL_BILLINGS_OF_TODAY_OF_USER,
    GET_ALL_BILLINGS_OF_CUSTOMER,
    GET_BILLING_DETAILS,
    ADD_BILLING,
    UPDATE_BILLING,
    DELETE_BILLING_DETAILS,
    BILLINGS_LOADING, DELETE_CUSTOMER_DETAILS,
} from "./types";
import {tokenConfig} from "./authActions";
import {returnErrors} from "./errorActions";
import {func} from "prop-types";
import moment from 'moment';
import {setCustomersLoading} from "./customerActions";

export const getAllBillings = () => dispatch => {
    dispatch(setBillingsLoading());
    axios
        .get('/api/billings')
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_BILLINGS,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_BILLINGS,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};
export const getAllBillingsOfToday = () => dispatch => {
    dispatch(setBillingsLoading());
    axios
        .get('/api/billings/today')
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_BILLINGS_OF_TODAY,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_BILLINGS_OF_TODAY,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};

export const getAllBillingsOfCustomer = (id) => dispatch => {
    dispatch(setBillingsLoading());
    axios
        .get('/api/billings/customer/' + id )
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_BILLINGS_OF_CUSTOMER,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_BILLINGS_OF_CUSTOMER,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};

export const getAllBillingsOfUser = (id) => dispatch => {
    dispatch(setBillingsLoading());
    axios
        .get('/api/billings/user/' + id )
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_BILLINGS_OF_USER,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_BILLINGS_OF_USER,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};

export const getAllBillingsOfTodayOfUser = (id) => dispatch => {
    dispatch(setBillingsLoading());
    axios
        .get('/api/billings/today/user/'+ id)
        .then(res => {
            let data = res.data;
            for(let i = 0; i< res.data.length; i++){
                data[i] = {
                    ...data[i],
                    id: i+1,
                }
            }
            dispatch({
                type: GET_ALL_BILLINGS_OF_TODAY_OF_USER,
                payload: {
                    data: data,
                    errorMessage: false
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ALL_BILLINGS_OF_TODAY_OF_USER,
                payload: {
                    data: false,
                    errorMessage: err?.message
                }
            })
        })
};

export const getBillingDetails = (id) => {
    return (dispatch) => {
    axios
        .get('/api/billings/' + id)
            .then(function (response){
                dispatch({
                    type: GET_BILLING_DETAILS,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    }
                })
        })
        .catch(function (error) {
            dispatch({
                type: GET_BILLING_DETAILS,
                payload: {
                    data: false,
                    errorMessage: error?.message,
                }
            })
        })
    }
}

export const addbilling = (data) => {
    return (dispatch, getState) => {
        dispatch(setBillingsLoading());
        axios
        .post('/api/billings', data, tokenConfig(getState))
            .then(function (response) {
                    dispatch({
                        type: ADD_BILLING,
                        payload: {
                            data: response?.data,
                            errorMessage: false
                        },
                    });
            })
            .catch(function (error){
                dispatch({
                    type: ADD_BILLING,
                    payload: {
                        data: false,
                        errorMessage: error?.message,
                    }
                });
        })
    };
}

/*export const addBilling = billing => (dispatch, getState) => {
    axios
        .post('/api/billings', billing, tokenConfig(getState))
        .then(res =>
            dispatch({
                type: ADD_BILLING,
                payload: res.data
            })
        ).catch(err => dispatch(returnErrors(err.response.data, err.response.status)))
};*/

export const updateBilling = (billing, id) => {
    return (dispatch, getState) => {
        axios
            .put("/api/billings/"+id, billing, tokenConfig(getState))
            .then(function (response) {
                dispatch({
                    type: UPDATE_BILLING,
                    payload: {
                        data: response?.data,
                        errorMessage: false,
                    },
                });
            })
            .catch(function (error) {
                dispatch({
                    type: UPDATE_BILLING,
                    payload: {
                        data: false,
                        errorMessage: error?.message
                    },
                });
            })
    };
}

    export const deleteDataBilling = () => {
        return (dispatch) => {
            dispatch({
                type: GET_BILLING_DETAILS,
                payload: {
                    data: false,
                    errorMessage: false,
                }
            });
            dispatch({
                type: ADD_BILLING,
                payload: {
                    data: false,
                    errorMessage: false,
                }
            });
        }
    }

    export const deleteBilling = id => (dispatch, getState) => {
        dispatch(setBillingsLoading());
        axios
            .put(`/api/billings/delete/${id}`, tokenConfig(getState))
            .then(res =>
                dispatch({
                    type: DELETE_BILLING_DETAILS,
                    payload: id
                })
            ).catch(err => dispatch(returnErrors(err?.response?.data, err.response?.status)))
    };
/*
    export const deleteBilling = id => (dispatch, getState) => {
        dispatch(setBillingsLoading());
        axios
            .delete(`/api/billings/${id}`, tokenConfig(getState))
            .then(res =>
                dispatch({
                    type: DELETE_BILLING_DETAILS,
                    payload: id
                })
            ).catch(err => dispatch(returnErrors(err?.response?.data, err?.response?.status)))
    };
 */

    export const setBillingsLoading = () => {
        return {
            type: BILLINGS_LOADING,
        };
    };

